import type { FC, ReactNode } from "react";
import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import type { Account } from "src/types/account";
import { Issuer } from "src/utils/auth";
import { useDispatch } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { CONSTS } from "src/consts";
import { useTranslation } from "react-i18next";
import { thunks as strategiesThunks } from "src/thunks/strategies";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { identifyUser, initUser, userReset } from "src/hooks/use-mixpanel";
import { thunks as settingsThunks } from "src/thunks/settings";
import { StrategyList } from "src/types/strategies";

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isImpersonated: boolean;
  hasError: boolean;
  account: Account | null;
}

enum ActionType {
  INITIALIZE = "INITIALIZE",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT",
  ERROR = "ERROR",
  SIGN_IN = "SIGN_IN",
  IMPERSONATE = "IMPERSONATE",
  SIGN_UP = "SIGN_UP",
  SIGN_OUT = "SIGN_OUT",
  CONFIRM_SIGN_IN = "CONFIRM_SIGN_IN",
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    isImpersonated: boolean;
    account: Account | null;
  };
};

type UpdateAccountAction = {
  type: ActionType.UPDATE_ACCOUNT;
  payload: {
    isAuthenticated: boolean;
    isImpersonated: boolean;
    account: Account | null;
  };
};

type ErrorAction = {
  type: ActionType.ERROR;
  payload: {
    hasError: boolean;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
};

type ConfirmSignIn = {
  type: ActionType.CONFIRM_SIGN_IN;
  payload: {
    account: Account;
  };
};

type ImpersonateAction = {
  type: ActionType.IMPERSONATE;
  payload: {
    account: Account;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    account: Account;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action =
  | InitializeAction
  | UpdateAccountAction
  | ErrorAction
  | SignInAction
  | ImpersonateAction
  | SignUpAction
  | ConfirmSignIn
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  hasError: false,
  isImpersonated: false,
  account: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, isImpersonated, account } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isImpersonated: isImpersonated,
      account: account,
    };
  },
  UPDATE_ACCOUNT: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, isImpersonated, account } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isImpersonated: isImpersonated,
      account: account,
    };
  },
  ERROR: (state: State, action: ErrorAction): State => {
    const { hasError } = action.payload;

    return {
      ...state,
      hasError,
    };
  },
  SIGN_IN: (state: State): State => {
    return {
      ...state,
      isAuthenticated: false,
    };
  },
  CONFIRM_SIGN_IN: (state: State, action: ConfirmSignIn): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      account: account,
    };
  },
  IMPERSONATE: (state: State, action: ImpersonateAction): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isImpersonated: true,
      account: account,
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { account } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      account: account,
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isImpersonated: false,
    account: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  initialize: () => Promise<void>;
  clearSettings: () => Promise<void>;
  signIn: (email: string) => Promise<void>;
  confirmSignIn: (token: string, email: string) => Promise<void>;
  impersonate: (accountId: string) => Promise<void>;
  stopImpersonation: () => Promise<void>;
  updateAccount: (
    firstName: string,
    lastName: string,
    profileImageId: string | undefined,
    profileImageUrl: string | undefined
  ) => Promise<void>;
  changeLanguage: (language: string) => Promise<void>;
  signUp: (email: string, strategyId: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  initialize: () => Promise.resolve(),
  clearSettings: () => Promise.resolve(),
  signIn: () => Promise.resolve(),
  confirmSignIn: () => Promise.resolve(),
  impersonate: () => Promise.resolve(),
  stopImpersonation: () => Promise.resolve(),
  updateAccount: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  changeLanguage: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const { i18n } = useTranslation();

  const clearSettings = useCallback(async (): Promise<void> => {
    //localStorage.removeItem(CONSTS.STRATEGY_ID_KEY);
  }, []);

  const initialize = useCallback(
    async (): Promise<void> => {
      try {
        var isImpersonated = true;
        var accountStr = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
        if (!accountStr) {
          accountStr = localStorage.getItem(CONSTS.USER_KEY);
          isImpersonated = false;
        }
        if (accountStr) {
          try {
            const strategies = (await reduxDispatch(
              strategiesThunks.getStrategies()
            )) as StrategyList[];

            if (strategies && strategies.length > 0) {
              var strategyId = localStorage.getItem(CONSTS.STRATEGY_ID_KEY);
              var strategy =
                strategies.find((s) => s.id === strategyId) || strategies[0];

              await reduxDispatch(
                settingsThunks.setStrategyId(
                  strategy ? strategy.id : strategies[0].id
                )
              );

              await reduxDispatch(strategyThunks.getStrategy(strategy.id));
            }
          } catch (err) {
            console.error(err);
            dispatch({
              type: ActionType.ERROR,
              payload: {
                hasError: true,
              },
            });
            return;
          }

          var account = JSON.parse(accountStr) as Account;

          if (!isImpersonated) {
            userReset();
            identifyUser(account.id);
            if (account.email) {
              initUser(account.email, account.email);
            }
          }

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              isImpersonated: isImpersonated,
              account: JSON.parse(accountStr),
            },
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              isImpersonated: false,
              account: null,
            },
          });
        }
      } catch (err) {
        console.error(err);

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            isImpersonated: false,
            account: null,
          },
        });

        dispatch({
          type: ActionType.ERROR,
          payload: {
            hasError: true,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language, reduxDispatch]
  );

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (email: string): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account/signin`,
        { email },
        undefined,
        i18n.language
      );
      dispatch({
        type: ActionType.SIGN_IN,
      });
    },
    [i18n]
  );

  const confirmSignIn = useCallback(
    async (token: string, email: string): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/account/confirm-signin`,
        { token: token, email: email },
        undefined,
        i18n.language
      );

      if (account) {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(account));
        if (account.langauge !== i18n.language) {
          i18n.changeLanguage(account.language);
        }
        dispatch({
          type: ActionType.CONFIRM_SIGN_IN,
          payload: {
            account: account,
          },
        });
      }
    },
    [i18n]
  );

  const impersonate = useCallback(
    async (accountId: string): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/host/impersonate`,
        { userId: accountId },
        undefined,
        i18n.language
      );

      if (account) {
        localStorage.removeItem(CONSTS.STRATEGY_ID_KEY);
        localStorage.setItem(
          CONSTS.IMPERSONATED_USER_KEY,
          JSON.stringify(account)
        );

        await clearSettings();
        await initialize();

        dispatch({
          type: ActionType.IMPERSONATE,
          payload: {
            account: account,
          },
        });
      }
    },
    [clearSettings, i18n.language, initialize]
  );

  const updateAccount = useCallback(
    async (
      firstName: string,
      lastName: string,
      profileImageId: string | undefined,
      profileImageUrl: string | undefined
    ): Promise<void> => {
      await fetchWrapper.post(
        `${api}/account`,
        { firstName, lastName, profileImageId },
        undefined,
        i18n.language
      );

      var isImpersonated = true;
      var account = localStorage.getItem(CONSTS.IMPERSONATED_USER_KEY);
      if (!account) {
        account = localStorage.getItem(CONSTS.USER_KEY);
        isImpersonated = false;
      }

      var json = JSON.parse(account!);
      json.firstName = firstName;
      json.lastName = lastName;
      json.profileImageId = profileImageId;
      json.profileImageUrl = profileImageUrl;

      if (isImpersonated) {
        localStorage.setItem(
          CONSTS.IMPERSONATED_USER_KEY,
          JSON.stringify(json)
        );
      } else {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(json));
      }

      dispatch({
        type: ActionType.UPDATE_ACCOUNT,
        payload: {
          isAuthenticated: true,
          isImpersonated: isImpersonated,
          account: json,
        },
      });
    },
    [i18n.language]
  );

  const changeLanguage = useCallback(
    async (language: string): Promise<void> => {
      await fetchWrapper.post(`${api}/account/language`, { language });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signUp = useCallback(
    async (email: string, strategyId: string): Promise<void> => {
      const account = await fetchWrapper.post(
        `${api}/account/signup`,
        { strategyId, email },
        undefined,
        i18n.language
      );
      if (account) {
        localStorage.setItem(CONSTS.USER_KEY, JSON.stringify(account));
        dispatch({
          type: ActionType.SIGN_UP,
          payload: {
            account: account,
          },
        });
      }
    },
    [dispatch, i18n.language]
  );

  const signOut = useCallback(
    async (): Promise<void> => {
      userReset();
      localStorage.removeItem(CONSTS.USER_KEY);
      localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);
      localStorage.removeItem(CONSTS.STRATEGY_ID_KEY);

      dispatch({ type: ActionType.SIGN_OUT });
      reduxDispatch({ type: "DESTROY_SESSION" });
      await clearSettings();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, reduxDispatch]
  );

  const stopImpersonation = useCallback(async (): Promise<void> => {
    localStorage.removeItem(CONSTS.IMPERSONATED_USER_KEY);
    await clearSettings();
    await initialize();
    //reduxDispatch({ type: 'DESTROY_SESSION' });
  }, [clearSettings, initialize]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        initialize,
        clearSettings,
        impersonate,
        signIn,
        confirmSignIn,
        stopImpersonation,
        updateAccount,
        signUp,
        changeLanguage,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
