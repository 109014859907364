import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { useSelector } from "src/store";
import type { Page as PageType } from "src/types/page";
import { Box, Stack } from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import StrategyContentRoot from "./strategy-content-root";
import { InstagramProfilePart } from "src/sections/dashboard/instagram/instagram-profile-part";
import { PlanType } from "src/types/strategy";
import { PaidAccess } from "src/sections/common/paid-access";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const { item } = useSelector((state) => state.strategy);

  if (!item) {
    return null;
  }
  return (
    <>
      <Seo
        title={t(tokens.recommendations.instagram.profile.title) as string}
      />
      <StrategyContentRoot>
        <Box
          sx={{
            "--Content-maxWidth": "var(--maxWidth-lg)",
            maxWidth: "var(--Content-maxWidth)",
            m: "var(--Content-margin)",
            p: "var(--Content-padding)",
            width: "var(--Content-width)",
          }}
        >
          <Box
            sx={{
              flex: "1 1 auto",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <Stack
              sx={{ px: 1, pb: 1 }}
              spacing={3}
            >
              {item?.planType === PlanType.Free ? (
                <PaidAccess
                  title={t(tokens.recommendations.instagram.profile.header)}
                />
              ) : (
                <InstagramProfilePart strategyId={item!.id} />
              )}
            </Stack>
          </Box>
        </Box>
      </StrategyContentRoot>
    </>
  );
};

export default Page;
