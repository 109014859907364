import { tokens } from "../tokens";

export const uk = {
  [tokens.landing.title]:
    "MakeItLab - генератор контент-стратегії на основі AI",
  [tokens.landing.hero.title1]: "Створи свою персональну",
  [tokens.landing.hero.title2]: "контент-стратегію",
  [tokens.landing.hero.subtitle]:
    "Перетвори свої навички, цінності та захоплення на потужну персональну контент-стратегію, <br /> яка допоможе тобі досягти цілей.",
  [tokens.landing.hero.button]: "Створити стратегію",
  [tokens.landing.nav.strategies]: "Стратегії",
  [tokens.landing.nav.singIn]: "Увійти",
  [tokens.landing.nav.singUp]: "Зареєструватися",
  [tokens.landing.nav.privacyPolicy]: "Політика конфіденційності",
  [tokens.landing.nav.termsOfUse]: "Умови використання",
  [tokens.landing.nav.panel]: "Панель управління",

  [tokens.nav.upgradeAccount]: "Змінити план",
  [tokens.nav.host]: "Хост",
  [tokens.nav.profile]: "Мій профіль",

  [tokens.sidebar.aboutMe]: "Профайл",
  [tokens.sidebar.content]: "Контент",
  [tokens.sidebar.ideas]: "Теми",
  [tokens.sidebar.leadMagnets]: "Лід-магніти",
  [tokens.sidebar.contentTemplates]: "Шаблони контенту",
  [tokens.sidebar.topicGenerator]: "Генератор тем",
  [tokens.sidebar.myBrand]: "Мій бренд",
  [tokens.sidebar.targetAudiences]: "Цільові аудиторії",
  [tokens.sidebar.storylines]: "Сюжетні лінії",
  [tokens.sidebar.fears]: "Страхи",
  [tokens.sidebar.recommendations]: "Рекомендації",
  [tokens.sidebar.product]: "Продукт",
  [tokens.sidebar.instagram]: "Instagram",
  [tokens.sidebar.general]: "Загальні",
  [tokens.sidebar.settings]: "Налаштування",
  [tokens.sidebar.calendar]: "Календар",
  [tokens.sidebar.workspaces.name]: "Стратегія",

  [tokens.wizard.aiHeader]: "AI-powered options",
  [tokens.wizard.showExample]: "Показати приклад",
  [tokens.wizard.placeholders.name]: "Ваше ім'я",
  [tokens.wizard.placeholders.profession]: "Деталі вашого досвіду",
  [tokens.wizard.placeholders.skills]: "Професійний навик",
  [tokens.wizard.placeholders.goals]: "Ваша ціль",
  [tokens.wizard.placeholders.productOrService.title]: "Назва",
  [tokens.wizard.placeholders.productOrService.description]: "Короткий опис",
  [tokens.wizard.placeholders.targetAudience.title]: "Назва",
  [tokens.wizard.placeholders.targetAudience.description]: "Короткий опис",
  [tokens.wizard.placeholders.valueProposition]:
    "Унікальна ціннісна пропозиція",
  [tokens.wizard.validators.maxItems]: "Максимальна кількість елементів: ",

  [tokens.wizard.step.title]: "Створення стратегії",
  [tokens.wizard.signup.title]: "Реєстрація",
  [tokens.wizard.signup.text]: "Ми на фінішній прямій",
  [tokens.wizard.signup.tooltip]:
    "Введи свій email і я надішлю тобі посилання на персональну контент-стратегію.",
  [tokens.wizard.signup.changeEmailText]:
    "Лист для входу надіслано на наступний e-mail:",
  [tokens.wizard.signup.changeButtonText]: "Змінити e-mail",
  [tokens.wizard.summary.title]: "Підсумок",
  [tokens.wizard.summary.text]: "Ми на фінішній прямій",
  [tokens.wizard.summary.tooltip]:
    "На основі твоїх відповідей я готовий створити для тебе персональну контент-стратегію. Це може зайняти якийсь час.",

  [tokens.recommendations.instagram.profile.header]: "Instagram профайл",
  [tokens.recommendations.instagram.profile.title]: "Instagram профайл",
  [tokens.recommendations.instagram.noDataFound]: "Дані не знайдено",

  [tokens.recommendations.fears.header]: "Страхи",
  [tokens.recommendations.fears.title]: "Страхи",
  [tokens.recommendations.fears.noDataFound]: "Дані не знайдено",

  [tokens.products.list.pageTitle]: "Продукти",
  [tokens.products.list.pageHeader]: "Продукти",
  [tokens.products.list.table.name]: "Назва",
  [tokens.products.list.table.targetAudiences]: "Цільові аудиторії",
  [tokens.products.list.table.actions]: "Дії",
  [tokens.products.list.deleteProductDialogTitle]: "Видалити продукт",
  [tokens.products.list.deleteProductDialogDescription]:
    "Ви впевнені, що хочете видалити цей продукт?",
  [tokens.products.list.buttons.add]: "Новий продукт",
  [tokens.products.list.noProductsFound]: "Немає продуктів",
  [tokens.products.list.errorLoadingProducts]: "Помилка завантаження продуктів",

  [tokens.products.create.pageTitle]: "Новий продукт",
  [tokens.products.create.pageHeader]: "Новий продукт",
  [tokens.products.create.backButton]: "Повернутися до продуктів",
  [tokens.products.create.form.name]: "Назва",
  [tokens.products.create.form.description]: "Опис",
  [tokens.products.create.form.uniqueValueProposition]:
    "Унікальна ціннісна пропозиція",
  [tokens.products.create.form.successMessage]: "Продукт успішно створено.",

  [tokens.products.details.pageTitle]: "Продукт",
  [tokens.products.details.pageHeader]: "Продукт",
  [tokens.products.details.backToProductsButton]: "Повернутися до продуктів",
  [tokens.products.details.form.name]: "Назва",
  [tokens.products.details.form.description]: "Опис",
  [tokens.products.details.form.landingPage]: "Лендінг сторінка",
  [tokens.products.details.form.uniqueValueProposition]:
    "Унікальна ціннісна пропозиція",
  [tokens.products.details.form.targetAudiences]: "Цільові аудиторії",
  [tokens.products.details.form.successMessage]: "Продукт успішно оновлено.",

  [tokens.topics.list.pageTitle]: "Банка тем",
  [tokens.topics.list.pageHeader]: "Банка тем",
  [tokens.topics.list.tabs.generator]: "Генератор тем",
  [tokens.topics.list.tabs.ideas]: "Теми",
  [tokens.topics.list.filters.text]: "Текст",
  [tokens.topics.list.filters.storyline]: "Сюжетна лінія",
  [tokens.topics.list.filters.product]: "Продукт",
  [tokens.topics.list.table.name]: "Назва",
  [tokens.topics.list.table.product]: "Продукт",
  [tokens.topics.list.table.date]: "Дата",
  [tokens.topics.list.table.storyline]: "Сюжетна лінія",
  [tokens.topics.list.table.contentGoalType]: "Ціль контенту",
  [tokens.topics.list.table.actions]: "Дії",
  [tokens.topics.list.deleteTopicDialogTitle]: "Видалити тему",
  [tokens.topics.list.deleteTopicDialogDescription]:
    "Ви впевнені, що хочете видалити цю тему?",
  [tokens.topics.list.buttons.add]: "Нова тема",
  [tokens.topics.list.noTopicsFound]: "Немає тем",
  [tokens.topics.list.errorLoadingTopics]: "Помилка завантаження тем",

  [tokens.topics.create.pageTitle]: "Нова тема",
  [tokens.topics.create.pageHeader]: "Нова тема",
  [tokens.topics.create.backButton]: "Повернутися до тем",
  [tokens.topics.create.form.name]: "Назва",
  [tokens.topics.create.form.description]: "Опис",
  [tokens.topics.create.form.date]: "Дата публікації",
  [tokens.topics.create.form.successMessage]: "Тему успішно створено.",

  [tokens.topics.details.pageTitle]: "Деталі теми",
  [tokens.topics.details.pageHeader]: "Деталі теми",
  [tokens.topics.details.backButton]: "Повернутися до тем",
  [tokens.topics.details.form.name]: "Назва",
  [tokens.topics.details.form.generationDialogHeader]: "AI генерація контенту",
  [tokens.topics.details.form.template]: "Шаблон контенту",
  [tokens.topics.details.form.instruction]: "Додаткові інструкції",
  [tokens.topics.details.form.description]: "Опис",
  [tokens.topics.details.form.content]: "Контент",
  [tokens.topics.details.form.titleRequired]: "Заголовок теми обов'язковий",
  [tokens.topics.details.form.generateButton]: "AI шаблон",
  [tokens.topics.details.form.date]: "Дата публікації",
  [tokens.topics.details.form.updateSuccessMessage]: "Тему успішно оновлено.",
  [tokens.topics.details.form.createSuccessMessage]: "Тему успішно створено.",

  [tokens.topics.generator.pageTitle]: "Генератор тем",
  [tokens.topics.generator.pageHeader]: "Генератор тем",
  [tokens.topics.generator.form.storyline]: "Сюжетна лінія",
  [tokens.topics.generator.form.product]: "Продукт",
  [tokens.topics.generator.form.instruction]: "Додаткові інструкції",
  [tokens.topics.generator.form.category]: "Рубрика",
  [tokens.topics.generator.buttons.addTopic]: "Додати",
  [tokens.topics.generator.buttons.removeTopic]: "Видалити",
  [tokens.topics.generator.buttons.generateTopics]: "Згенерувати теми",

  [tokens.leadMagnets.list.pageTitle]: "Лід-магніти",
  [tokens.leadMagnets.list.pageHeader]: "Лід-магніти",
  [tokens.leadMagnets.list.tabs.generator]: "Генератор лід-магнітів",
  [tokens.leadMagnets.list.tabs.leadMagnets]: "Лід-магніти",
  [tokens.leadMagnets.list.filters.text]: "Текст",
  [tokens.leadMagnets.list.filters.product]: "Продукт",
  [tokens.leadMagnets.list.table.name]: "Назва",
  [tokens.leadMagnets.list.table.type]: "Тип",
  [tokens.leadMagnets.list.table.complexity]: "Складність",
  [tokens.leadMagnets.list.table.product]: "Продукт",
  [tokens.leadMagnets.list.table.actions]: "Дії",
  [tokens.leadMagnets.list.deleteLeadMagnetDialogTitle]: "Видалити лід-магніт",
  [tokens.leadMagnets.list.deleteLeadMagnetDialogDescription]:
    "Ви впевнені, що хочете видалити цей лід-магніт?",
  [tokens.leadMagnets.list.buttons.add]: "Новий лід-магніт",
  [tokens.leadMagnets.list.noLeadMagnetsFound]: "Немає лід-магнітів",
  [tokens.leadMagnets.list.errorLoadingLeadMagnets]:
    "Помилка завантаження лід-магнітів",

  [tokens.leadMagnets.create.pageTitle]: "Новий лід-магніт",
  [tokens.leadMagnets.create.pageHeader]: "Новий лід-магніт",
  [tokens.leadMagnets.create.backButton]: "Повернутися до лід-магнітів",
  [tokens.leadMagnets.create.form.name]: "Назва",
  [tokens.leadMagnets.create.form.description]: "Опис",
  [tokens.leadMagnets.create.form.successMessage]:
    "Лід-магніт успішно створено.",

  [tokens.leadMagnets.details.pageTitle]: "Лід-магніт",
  [tokens.leadMagnets.details.pageHeader]: "Лід-магніт",
  [tokens.leadMagnets.details.backButton]: "Повернутися до лід-магнітів",
  [tokens.leadMagnets.details.form.name]: "Назва",
  [tokens.leadMagnets.details.form.description]: "Опис",
  [tokens.leadMagnets.details.form.successMessage]:
    "Лід-магніт успішно оновлено.",

  [tokens.leadMagnets.generator.pageTitle]: "Генератор лід-магнітів",
  [tokens.leadMagnets.generator.pageHeader]: "Генератор лід-магнітів",
  [tokens.leadMagnets.generator.form.product]: "Продукт",
  [tokens.leadMagnets.generator.form.instruction]: "Додаткові інструкції",
  [tokens.leadMagnets.generator.buttons.addLeadMagnet]: "Зберегти лід-магніт",
  [tokens.leadMagnets.generator.buttons.removeLeadMagnet]:
    "Видалити лід-магніт",
  [tokens.leadMagnets.generator.buttons.generateLeadMagnets]:
    "Згенерувати лід-магніти",

  [tokens.storylines.list.pageTitle]: "Сюжетні лінії",
  [tokens.storylines.list.pageHeader]: "Сюжетні лінії",
  [tokens.storylines.list.table.name]: "Назва",
  [tokens.storylines.list.table.contentGoal]: "Ціль контенту",
  [tokens.storylines.list.table.actions]: "Дії",
  [tokens.storylines.list.deleteStorylineDialogTitle]: "Видалити сюжетну лінію",
  [tokens.storylines.list.deleteStorylineDialogDescription]:
    "Ви впевнені, що хочете видалити цю сюжетну лінію?",
  [tokens.storylines.list.buttons.add]: "Нова сюжетна лінія",
  [tokens.storylines.list.noStorylinesFound]: "Немає сюжетних ліній",
  [tokens.storylines.list.errorLoadingStorylines]:
    "Помилка завантаження сюжетних ліній",

  [tokens.storylines.create.pageTitle]: "Нова сюжетна лінія",
  [tokens.storylines.create.pageHeader]: "Нова сюжетна лінія",
  [tokens.storylines.create.backButton]: "Повернутися до сюжетних ліній",
  [tokens.storylines.create.form.name]: "Назва",
  [tokens.storylines.create.form.contentGoal]: "Ціль контенту",
  [tokens.storylines.create.form.description]: "Опис",
  [tokens.storylines.create.form.categories]: "Рубрики",
  [tokens.storylines.create.form.placeholders.name]: "Назва",
  [tokens.storylines.create.form.placeholders.description]: "Опис",
  [tokens.storylines.create.form.noCategories]: "Немає рубрик",
  [tokens.storylines.create.form.successMessage]:
    "Сюжетну лінію успішно створено.",

  [tokens.storylines.details.pageTitle]: "Сюжетна лінія",
  [tokens.storylines.details.pageHeader]: "Сюжетна лінія",
  [tokens.storylines.details.backButton]: "Повернутися до сюжетних ліній",
  [tokens.storylines.details.form.name]: "Назва",
  [tokens.storylines.details.form.contentGoalType]: "Ціль контенту",
  [tokens.storylines.details.form.description]: "Опис",
  [tokens.storylines.details.form.categories]: "Рубрики",
  [tokens.storylines.details.form.placeholders.name]: "Назва",
  [tokens.storylines.details.form.placeholders.description]: "Опис",
  [tokens.storylines.details.form.noCategories]: "Немає рубрик",
  [tokens.storylines.details.form.noDescription]: "Немає опису",
  [tokens.storylines.details.form.successMessage]:
    "Сюжетну лінію успішно оновлено.",

  [tokens.storylinesCategories.details.form.topics]: "Теми",
  [tokens.storylinesCategories.details.form.noTopics]: "Немає тем",
  [tokens.storylinesCategories.details.form.addTopic]: "Додати тему",
  [tokens.storylinesCategories.details.form.generateTopics]: "Згенерувати теми",
  [tokens.storylinesCategories.details.form.topicUpdatedMessage]:
    "Тему успішно оновлено.",
  [tokens.storylinesCategories.details.form.topicCreatedMessage]:
    "Тему успішно створено.",
  [tokens.storylinesCategories.details.form.topicUpdatedMessage]:
    "Категорію успішно оновлено.",
  [tokens.storylinesCategories.details.deleteTopicDialogTitle]: "Видалити тему",
  [tokens.storylinesCategories.details.deleteTopicDialogDescription]:
    "Ви впевнені, що хочете видалити цю тему?",
  [tokens.storylinesCategories.details.deleteCategoryDialogTitle]:
    "Видалити рубрику",
  [tokens.storylinesCategories.details.deleteCategoryDialogDescription]:
    "Ви впевнені, що хочете видалити цю рубрику?",

  [tokens.contentPlaceholders.pageHeader]: "Тема",
  [tokens.contentPlaceholders.form.name]: "Назва",
  [tokens.contentPlaceholders.form.description]: "Опис",
  [tokens.contentPlaceholders.form.contentGoal]: "Ціль контенту",
  [tokens.contentPlaceholders.form.channel]: "Канал",
  [tokens.contentPlaceholders.form.date]: "Дата",
  [tokens.contentPlaceholders.form.content]: "Контент",
  [tokens.contentPlaceholders.options.newTopic]: "Нова тема",
  [tokens.contentPlaceholders.options.selectTopic]:
    "Вибрати тему із збережених",
  [tokens.contentPlaceholders.options.generateTopic]: "Згенерувати тему",
  [tokens.contentPlaceholders.deleteContentPlaceholderDialogDescription]:
    "Ви впевнені, що хочете видалити цей елемент?",
  [tokens.contentPlaceholders.deleteContentPlaceholderDialogTitle]:
    "Видалити елемент",

  [tokens.calendar.contentPlanSchedule.pageHeader]: "Планування контенту",
  [tokens.calendar.generateTopic.pageHeader]: "Генерування тем",
  [tokens.calendar.selectTopic.pageHeader]: "Вибір теми",
  [tokens.calendar.selectTopic.errorLoadingTopics]: "Помилка завантаження тем",
  [tokens.calendar.selectTopic.noTopicsFound]: "Тем не знайдено",

  [tokens.contentTemplates.list.pageTitle]: "Шаблони контенту",
  [tokens.contentTemplates.list.pageHeader]: "Шаблони контенту",
  [tokens.contentTemplates.list.table.name]: "Назва",
  [tokens.contentTemplates.list.table.actions]: "Дії",
  [tokens.contentTemplates.list.deleteContentTemplateDialogTitle]:
    "Видалити шаблон контенту",
  [tokens.contentTemplates.list.deleteContentTemplateDialogDescription]:
    "Ви впевнені, що хочете видалити цей шаблон контенту?",
  [tokens.contentTemplates.list.buttons.add]: "Новий шаблон контенту",
  [tokens.contentTemplates.list.noContentTemplatesFound]:
    "Немає шаблонів контенту",
  [tokens.contentTemplates.list.errorLoadingContentTemplates]:
    "Помилка завантаження шаблонів контенту",
  [tokens.contentTemplates.create.pageTitle]: "Новий шаблон контенту",
  [tokens.contentTemplates.create.pageHeader]: "Новий шаблон контенту",
  [tokens.contentTemplates.create.backButton]:
    "Повернутися до шаблонів контенту",
  [tokens.contentTemplates.create.form.name]: "Назва",
  [tokens.contentTemplates.create.form.instruction]: "Інструкція",
  [tokens.contentTemplates.create.form.examples]: "Приклади",
  [tokens.contentTemplates.create.form.noExamples]: "Немає прикладів",
  [tokens.contentTemplates.create.form.successMessage]:
    "Шаблон контенту успішно створено.",
  [tokens.contentTemplates.details.pageTitle]: "Шаблон контенту",
  [tokens.contentTemplates.details.pageHeader]: "Шаблон контенту",
  [tokens.contentTemplates.details.backButton]:
    "Повернутися до шаблонів контенту",
  [tokens.contentTemplates.details.form.name]: "Назва",
  [tokens.contentTemplates.details.form.instruction]: "Інструкція",
  [tokens.contentTemplates.details.form.examples]: "Приклади",
  [tokens.contentTemplates.details.form.noExamples]: "Немає прикладів",
  [tokens.contentTemplates.details.form.successMessage]:
    "Шаблон контенту успішно оновлено.",

  [tokens.targetAudiences.list.pageTitle]: "Цільові аудиторії",
  [tokens.targetAudiences.list.pageHeader]: "Цільові аудиторії",
  [tokens.targetAudiences.list.table.name]: "Назва",
  [tokens.targetAudiences.list.table.products]: "Продукти",
  [tokens.targetAudiences.list.table.actions]: "Дії",
  [tokens.targetAudiences.list.deleteTargetAudienceDialogTitle]:
    "Видалити цільову аудиторію",
  [tokens.targetAudiences.list.deleteTargetAudienceDialogDescription]:
    "Ви ві, що хочете видалити цільову аудиторію?",
  [tokens.targetAudiences.list.buttons.add]: "Нова цільова аудиторія",
  [tokens.targetAudiences.list.noTargetAudiencesFound]:
    "Немає цільових аудиторій",
  [tokens.targetAudiences.list.errorLoadingTargetAudiences]:
    "Помимилка завантаження цільових аудиторій",

  [tokens.targetAudiences.create.pageTitle]: "Нова цільова аудиторія",
  [tokens.targetAudiences.create.pageHeader]: "Нова цільова аудиторія",
  [tokens.targetAudiences.create.backButton]:
    "Повернутися до цільових аудиторій",
  [tokens.targetAudiences.create.form.name]: "Назва",
  [tokens.targetAudiences.create.form.description]: "Опис",
  [tokens.targetAudiences.create.form.content]: "Контент",
  [tokens.targetAudiences.create.form.successMessage]:
    "Цільова аудиторія успішно створена.",
  [tokens.targetAudiences.details.sections.goals]: "Цілі",
  [tokens.targetAudiences.details.sections.pains]: "Болі",
  [tokens.targetAudiences.edit.pageTitle]: "Цільова аудиторія",
  [tokens.targetAudiences.edit.pageHeader]: "Цільова аудиторія",
  [tokens.targetAudiences.edit.backToTargetAudiencessButton]:
    "Повернутися до цільових аудиторій",
  [tokens.targetAudiences.edit.form.name]: "Назва",
  [tokens.targetAudiences.edit.form.description]: "Опис",
  [tokens.targetAudiences.edit.form.idealCustomer]: "Ідеальний покупець",
  [tokens.targetAudiences.edit.form.pains]: "Болі",
  [tokens.targetAudiences.edit.form.goals]: "Цілі",
  [tokens.targetAudiences.edit.form.successMessage]:
    "Цільова аудиторія успішно оновлено.",

  [tokens.aboutMe.header]: "Профайл",
  [tokens.aboutMe.title]: "Профайл",
  [tokens.aboutMe.noAboutMeFound]: "Дані не знайдено",
  [tokens.aboutMe.sections.aboutMe]: "Про мене",
  [tokens.aboutMe.sections.mission]: "Місія",
  [tokens.aboutMe.sections.goals]: "Мої цілі",
  [tokens.aboutMe.sections.values]: "Цінності",
  [tokens.aboutMe.sections.goalsPriority]: "Пріоритетність цілей",
  [tokens.aboutMe.sections.skills]: "Мої навики",
  [tokens.aboutMe.sections.productsOrServices]:
    "Мої продукти чи сервіси для досягнення цілей",
  [tokens.aboutMe.sections.productOrService]: "З чого я розпочну?",
  [tokens.aboutMe.sections.positionings]: "Чим я можу вирізнятись?",
  [tokens.aboutMe.sections.voice]: "Мій голос",
  [tokens.aboutMe.sections.expertContent]: "Експертний контент",
  [tokens.aboutMe.sections.personalContent]: "Особистий контент",
  [tokens.aboutMe.sections.contentVision]: "Яким я бачу свій контент?",
  [tokens.aboutMe.sections.channels]: "Мої канали комунікації",
  [tokens.aboutMe.sections.strengths]: "Мої сильні сторони",
  [tokens.aboutMe.sections.slogan]: "Слоган",

  [tokens.fears.title]: "Страхи",
  [tokens.fears.header]: "Страхи",
  [tokens.fears.errorLoading]: "Не вдалося завантажити дані",
  [tokens.fears.noFearsFound]: "Дані не знайдено",

  [tokens.strategySettings.title]: "Налаштування",
  [tokens.strategySettings.header]: "Налаштування",
  [tokens.strategySettings.export.header]: "Експорт стратегії",
  [tokens.strategySettings.export.explanation]:
    "Завантажте вашу стратегію у форматі PDF. Це може зайняти деякий час.",
  [tokens.strategySettings.buttons.export]: "Експортувати стратегію",
  [tokens.strategySettings.buttons.delete]: "Видалити стратегію",
  [tokens.strategySettings.buttons.upgrade]: "Змінити план",
  [tokens.strategySettings.form.name]: "Назва стратегії",
  [tokens.strategySettings.form.successMessage]:
    "Налаштування успішно збережено",
  [tokens.strategySettings.tabs.general]: "Загальні",
  [tokens.strategySettings.tabs.channels]: "Канали",
  [tokens.strategySettings.errorLoading]: "Не вдалося завантажити дані",
  [tokens.strategySettings.noStrategySettingsFound]: "Дані не знайдено",
  [tokens.strategySettings.detele.header]: "Видалення стратегії",
  [tokens.strategySettings.detele.explanation]:
    "Після видалення стратегії ви не зможете відновити її.",
  [tokens.strategySettings.detele.deleteStrategyDialogTitle]:
    "Видалення стратегії",
  [tokens.strategySettings.detele.deleteStrategyDialogDescription]:
    "Ви впевнені, що хочете видалити цю стратегію?",
  [tokens.strategySettings.plan.header]: "Тарифний план",
  [tokens.strategySettings.plan.status]: "Cтатус",
  [tokens.strategySettings.plan.active]: "Активний",
  [tokens.strategySettings.plan.currentPlan]: "Поточний план",

  [tokens.channels.form.noChannels]: "Немає каналів",
  [tokens.channels.form.successMessage]: "Канали успішно збережено",
  [tokens.channels.errorLoadingChannels]: "Не вдалося завантажити дані",

  [tokens.export.sections.instagram]: "Instagram",
  [tokens.export.sections.fears]: "Страхи",
  [tokens.export.sections.storylines]: "Сюжетні лінії",
  [tokens.export.sections.products]: "Продукти",
  [tokens.export.sections.targetAudiences]: "Цільові аудиторії",

  [tokens.payment.statuses.approved]: "Сплачено",
  [tokens.payment.statuses.pending]: "Очікується підтвердження",
  [tokens.payment.statuses.declined]: "Відхилено",
  [tokens.payment.statuses.unknown]: "Невідомий",
  [tokens.payment.statuses.refunded]: "Повернено",
  [tokens.payment.statuses.noPayment]: "Оплата відсутня",
  [tokens.payment.success.header]: "Деталі платежу",
  [tokens.payment.success.title]: "Деталі платежу",
  [tokens.payment.success.openDashboardButton]: "Відкрити панель управління",
  [tokens.payment.success.product]: "План",
  [tokens.payment.success.email]: "Email",
  [tokens.payment.success.status]: "Статус",
  [tokens.payment.success.orderReference]: "Номер замовлення",
  [tokens.payment.success.messages.approved]: "Ваша оплата успішно оброблена",
  [tokens.payment.success.messages.pending]:
    "Ви отримаєте повний доступ до продукту після підтвердження платежу",
  [tokens.payment.success.messages.declined]:
    "Платіж відхилено. Спробуйте знову або зв'яжіться з нами",
  [tokens.payment.success.messages.unknown]:
    "Статус платежу невідомий. Зв’яжіться з нами.",

  [tokens.payment.error.header]: "Помилка платежу",
  [tokens.payment.error.title]: "Помилка платежу",
  [tokens.payment.error.message]:
    "Під час обробки вашого платежу сталася помилка. Спробуйте знову.<br/><br/>Якщо проблема не зникає, зв’яжіться з нами.",
  [tokens.payment.error.orderReference]: "Номер замовлення",
  [tokens.payment.error.unknownStatus]: "Невідомий статус",

  [tokens.account.signUp.title]: "Створити обліковий запис",
  [tokens.account.signUp.header]: "Створити обліковий запис",
  [tokens.account.signUp.form.firstName]: "Ім'я *",
  [tokens.account.signUp.form.lastName]: "Прізвище *",
  [tokens.account.signUp.form.phone]: "Номер телефону *",
  [tokens.account.signUp.form.email]: "Адреса електронної пошти *",
  [tokens.account.signUp.form.password]: "Пароль *",
  [tokens.account.signUp.form.createAccountButton]: "Створити обліковий запис",
  [tokens.account.signUp.form.termsOfUseLink]: "умови використання",
  [tokens.account.signUp.form.privacyPolicyLink]: "політику конфіденційності",
  [tokens.account.signUp.form.andSeparator]: "та",
  [tokens.account.signUp.form.agreementText]: "Я прочитав/ла",
  [tokens.account.signUp.form.signinLink]: "Увійти",
  [tokens.account.signUp.form.hasAccount]: "У вас вже є обліковий запис?",

  [tokens.account.signIn.title]: "Увійти",
  [tokens.account.signIn.header]: "Увійти до MakeItLab",
  [tokens.account.signIn.form.email]: "Адреса електронної пошти *",
  [tokens.account.signIn.form.signInButton]: "Увійти",
  [tokens.account.signIn.form.termsOfUseLink]: "умови використання",
  [tokens.account.signIn.form.privacyPolicyLink]: "політику конфіденційності",
  [tokens.account.signIn.form.andSeparator]: "та",
  [tokens.account.signIn.form.agreementText]: "Я прочитав/ла",

  [tokens.account.emailSent.title]: "Лист надіслано",
  [tokens.account.emailSent.header]: "Лист надіслано",
  [tokens.account.emailSent.message]:
    "Лист з посиланням для входу надіслано на ваш e-mail. Будь ласка, перевірте свою поштову скриньку.",

  [tokens.account.confirmSignIn.title]: "Підтвердити вхід",
  [tokens.account.confirmSignIn.expiredToken]:
    "Термін дії посилання закінчився. Спробуйте увійти знову",
  [tokens.account.confirmSignIn.strategyIsFinished]: "Стратегія вже завершена.",

  [tokens.account.resetPassword.title]: "Скинути пароль",
  [tokens.account.resetPassword.header]: "Скинути пароль",
  [tokens.account.resetPassword.signInLink]: "Увійти",
  [tokens.account.resetPassword.form.passwordChangedMessage]:
    "Ваш пароль успішно змінено. Перейдіть на сторінку входу.",
  [tokens.account.resetPassword.form.password]: "Пароль *",
  [tokens.account.resetPassword.form.confirmPassword]: "Підтвердити пароль *",
  [tokens.account.resetPassword.form.resetButton]: "Скинути пароль",
  [tokens.account.resetPassword.form.linkInvalidError]:
    "Недійсне посилання. Спробуйте скинути пароль знову.",
  [tokens.account.resetPassword.form.linkInvalidOrExpiredError]:
    "Посилання для відновлення пароля минуло термін дії або недійсне. Спробуйте скинути пароль знову.",

  [tokens.account.forgotPassword.title]: "Забули пароль",
  [tokens.account.forgotPassword.header]: "Забули пароль",
  [tokens.account.forgotPassword.signInLink]: "Увійти",
  [tokens.account.forgotPassword.form.email]: "Адреса електронної пошти *",
  [tokens.account.forgotPassword.form.emailSentMessage]:
    "Лист з посиланням на відновлення паролю надісланий. Будь ласка, перевірте свою поштову скриньку.",
  [tokens.account.forgotPassword.form.resetButton]: "Відновити пароль",

  [tokens.account.popover.account]: "Обліковий запис",
  [tokens.account.popover.impersonate]: "Увійти як користувач",
  [tokens.account.popover.stopImpersonation]: "Зупинити вхід під користувачем",
  [tokens.account.popover.signOut]: "Вийти",

  [tokens.account.colorScheme.light]: "Світла",
  [tokens.account.colorScheme.dark]: "Темна",
  [tokens.account.colorScheme.scheme]: "Кольорова схема",

  [tokens.account.profile.title]: "Обліковий запис",
  [tokens.account.profile.header]: "Обліковий запис",
  [tokens.account.profile.passwordHeader]: "Пароль",
  [tokens.account.profile.accountDetailsHeader]: "Деталі облікового запису",
  [tokens.account.profile.accountProfileImage]: "Фото профілю",
  [tokens.account.profile.tab.general]: "Деталі",
  [tokens.account.profile.form.firstName]: "Ім'я *",
  [tokens.account.profile.form.lastName]: "Прізвище *",
  [tokens.account.profile.form.email]: "Адреса електронної пошти *",
  [tokens.account.profile.form.currentPassword]: "Поточний пароль *",
  [tokens.account.profile.form.newPassword]: "Новий пароль *",
  [tokens.account.profile.form.confirmPassword]: "Підтвердити пароль *",
  [tokens.account.profile.form.passwordUpdatedMessage]: "Ваш пароль оновлено.",
  [tokens.account.profile.form.accountUpdatedMessage]:
    "Ваш обліковий запис оновлено.",

  [tokens.account.impersonate.form.header]: "Увійти як користувач",
  [tokens.account.impersonate.form.searchForUser]: "Користувач",
  [tokens.account.impersonate.form.buttons.impersonate]: "Увійти",

  [tokens.contact.title]: "Контакти",
  [tokens.contact.header]: "Зв'яжіться з нами",
  [tokens.contact.subHeader]:
    "Наші дружелюбні експерти покажуть вам MakeItLab та налаштують його під ваші потреби.",
  [tokens.contact.form.firstName]: "Ім'я *",
  [tokens.contact.form.lastName]: "Прізвище *",
  [tokens.contact.form.phone]: "Номер телефону *",
  [tokens.contact.form.email]: "Адреса електронної пошти *",
  [tokens.contact.form.message]: "Повідомлення *",
  [tokens.contact.form.sendButton]: "Надіслати повідомлення",
  [tokens.contact.form.agreementText]: "Надсилаючи це, ви погоджуєтеся з",
  [tokens.contact.form.termsOfUseLink]: "Умовами використання",
  [tokens.contact.form.privacyPolicyLink]: "Політикою конфіденційності",
  [tokens.contact.form.andSeparator]: "та",
  [tokens.contact.form.successTitle]: "Запит надіслано",
  [tokens.contact.form.successMessage1]:
    "Ми зв'яжемося з вами найближчим часом.",
  [tokens.contact.form.successMessage2]: "Дякуємо за інтерес до MakeItLab!",

  [tokens.errors.serverError.title]: "Сервіс недоступний",
  [tokens.errors.serverError.header]: "Сервіс недоступний",
  [tokens.errors.serverError.message]:
    "Можливо, триває технічне обслуговування або тимчасове перевантаження.",
  [tokens.errors.notFound.title]: "Сторінку не знайдено",
  [tokens.errors.notFound.header]: "Сторінку не знайдено",
  [tokens.errors.notFound.message]:
    "Сторінка, яку ви шукаєте, не існує або була видалена.",

  [tokens.general.languageChanged]: "Мову змінено",
  [tokens.general.language]: "Мова",
  [tokens.general.newAppVersion.description]: "Доступна нова версія додатку.",
  [tokens.general.newAppVersion.action]: "Оновити",

  [tokens.general.placeholders.editor]: "Введіть текст...",

  [tokens.general.filters.emptyOptions]: "Немає опцій",
  [tokens.general.formError]: "Щось пішло не так!",
  [tokens.general.paginationRowsPerPage]: "Записів:",
  [tokens.general.loading]: "Завантаження...",

  [tokens.general.validators.user.conflict]:
    "Користувач із таким email вже доданий",
  [tokens.general.validators.employee.conflict]: "Співробітник вже доданий",
  [tokens.general.validators.employee.accountConnected]:
    "Даний email вже приєднано до іншого співробітника",
  [tokens.general.validators.role.required]: "Обов'язкове поле",
  [tokens.general.validators.role.min]: "Потрібна принаймні одна роль",
  [tokens.general.validators.password.minLength]:
    "Пароль має бути мінімум 6 символів",
  [tokens.general.validators.passwordConfirm.notMatch]: "Паролі не співпадають",
  [tokens.general.validators.policy.required]: "Обов'язкове поле",
  [tokens.general.validators.invalidEmail]: "Невірний формат email",
  [tokens.general.validators.roleRequired]: "Обов'язкове поле",
  [tokens.general.validators.maxLength255]:
    "Поле має містити не більше 255 символів",
  [tokens.general.validators.maxLength50]:
    "Поле має містити не більше 50 символів",
  [tokens.general.validators.required]: "Обов'язкове поле",
  [tokens.general.validators.oneItemRequired]:
    "Обов'язково потрібен хоча б один елемент",

  [tokens.general.filepicker.titleHighlight]: "Натисніть, щоб завантажити",
  [tokens.general.filepicker.titleNormal]: "або перетягніть",
  [tokens.general.filepicker.imageExtensions]: "(JPG or PNG), 5MB макс.",
  [tokens.general.filepicker.excelExtensions]: "(XLS або XLSX)",
  [tokens.general.filepicker.fileSizeError]: "Файл не може бути більше 5MB",
  [tokens.general.filepicker.deleteImage]: "Видалити",
  [tokens.general.filepicker.imageUploaded]: "Зображення завантажено",
  [tokens.general.actions.delete]: "Видалити",
  [tokens.general.actions.edit]: "Редагувати",
  [tokens.general.aiGeneration.pageHeader]: "Генерація контенту",
  [tokens.general.plans.basic]: "Базовий",
  [tokens.general.plans.professional]: "Професійний",
  [tokens.general.plans.free]: "Безкоштовний",
  [tokens.general.contentGoalTypes.notSelected]: "Не вибрано",
  [tokens.general.contentGoalTypes.engagement]: "Залучення аудиторії",
  [tokens.general.contentGoalTypes.community]: "Побудова спільноти",
  [tokens.general.contentGoalTypes.selling]: "Продаж",
  [tokens.general.complexity.unknown]: "Невідомо",
  [tokens.general.complexity.easy]: "Простий",
  [tokens.general.complexity.medium]: "Середній",
  [tokens.general.complexity.complicated]: "Складний",
  [tokens.general.buttons.ok]: "Ok",
  [tokens.general.buttons.delete]: "Видалити",
  [tokens.general.buttons.edit]: "Редагувати",
  [tokens.general.buttons.add]: "Додати",
  [tokens.general.buttons.copy]: "Копіювати",
  [tokens.general.buttons.invite]: "Запросити",
  [tokens.general.buttons.update]: "Оновити",
  [tokens.general.buttons.save]: "Зберегти",
  [tokens.general.buttons.archive]: "Архівувати",
  [tokens.general.buttons.create]: "Створити",
  [tokens.general.buttons.generate]: "Згенерувати",
  [tokens.general.buttons.cancel]: "Відміна",
  [tokens.general.buttons.close]: "Закрити",
  [tokens.general.buttons.more]: "Більше",
  [tokens.general.buttons.loadMore]: "Завантажити більше",
  [tokens.general.buttons.tryAgain]: "Спробувати ще раз",
  [tokens.general.buttons.backToHome]: "Повернутися на головну",
  [tokens.general.buttons.addItem]: "Додати",
  [tokens.general.buttons.next]: "Далі →",
  [tokens.general.buttons.copy]: "Копіювати",
  [tokens.general.buttons.back]: "Назад",
  [tokens.general.buttons.goBack]: "Повернутися назад",
  [tokens.general.buttons.generateAgain]: "Згенерувати знову",
  [tokens.general.buttons.generateStrategy]: "Згенерувати стратегію",
  [tokens.general.buttons.finish]: "Завершити",
};
