import { type FC } from "react";
import { Strategy } from "src/types/strategy";
import { Card, CardContent, Typography } from "@mui/material";

interface InstagramProfileDetailsProps {
  strategy: Strategy;
}

export const InstagramProfileDetails: FC<InstagramProfileDetailsProps> = (
  props
) => {
  const { strategy } = props;

  return (
    <>
      <Card>
        <CardContent>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {strategy.instagramTitle}
          </Typography>

          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: strategy.instagramDescription ?? "",
            }}
          ></Typography>
        </CardContent>
      </Card>
    </>
  );
};
