import { type FC } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Product } from "src/types/product";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { ProductEditForm } from "./product-edit-form";

interface ProductDetailsProps {
  product: Product;
}

export const ProductDetails: FC<ProductDetailsProps> = (props) => {
  const { product } = props;
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <Stack spacing={4}>
      {isEditing && (
        <>
          <ProductEditForm
            product={product}
            onSave={() => setIsEditing(false)}
            onCancel={() => setIsEditing(false)}
          />
        </>
      )}
      {!isEditing && (
        <Card>
          <CardContent>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Typography variant="h4">{product.name}</Typography>

              <IconButton onClick={() => setIsEditing(true)}>
                <EditIcon />
              </IconButton>
            </Stack>
            <Stack
              sx={{ mb: 2 }}
              direction="row"
              spacing={1}
              alignContent={"center"}
              alignItems={"center"}
            >
              💎
              <Typography variant="body1">
                {product.uniqueValueProposition.replaceAll("\n", "<br/>")}
              </Typography>
            </Stack>

            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: product.description.replaceAll("\n", "<br/>"),
              }}
            />
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};
