export const paths = {
  index: "/",
  home: "/",
  auth: {
    custom: {
      signIn: "/auth/custom/sign-in",
      signUp: "/auth/custom/sign-up",
      resetPassword: "/auth/custom/reset-password",
    },
    auth0: {
      callback: "/auth/auth0/callback",
      signIn: "/auth/auth0/sign-in",
      signUp: "/auth/auth0/sign-up",
      signOut: "/auth/auth0/sign-out",
      profile: "/auth/auth0/profile",
    },
    samples: {
      signIn: {
        centered: "/auth/samples/sign-in/centered",
        split: "/auth/samples/sign-in/split",
      },
      signUp: {
        centered: "/auth/samples/sign-up/centered",
        split: "/auth/samples/sign-up/split",
      },
      updatePassword: {
        centered: "/auth/samples/update-password/centered",
        split: "/auth/samples/update-password/split",
      },
      resetPassword: {
        centered: "/auth/samples/reset-password/centered",
        split: "/auth/samples/reset-password/split",
      },
      verifyCode: {
        centered: "/auth/samples/verify-code/centered",
        split: "/auth/samples/verify-code/split",
      },
    },
  },
  wizard: {
    step: "/wizard/:strategyId/step/:step",
    finish: "/wizard/:strategyId/finish",
    signup: "/wizard/:strategyId/signup",
  },
  domain: "/domain",
  profile: "/profile",
  instagramProfile: "/instagram-profile",
  settings: "/settings",
  fears: "/fears",
  products: {
    details: "/product",
    edit: "/products/:productId/edit",
  },
  topicGenerator: "/topic-generator",
  calendar: "/calendar",
  topics: {
    create: "/topics/create",
    list: "/topics",
    details: "/topics/:topicId",
  },
  leadMagnets: {
    create: "/lead-magnets/create",
    list: "/lead-magnets",
    details: "/lead-magnets/:leadMagnetId",
  },
  storylines: {
    create: "/storylines/create",
    list: "/storylines",
    edit: "/storylines/:storylineId/edit",
    details: "/storylines/:storylineId",
  },
  storylineCategories: {
    details: "/storylines/:storylineId/categories/:categoryId",
  },
  contentTemplates: {
    create: "/content-templates/create",
    list: "/content-templates",
    edit: "/content-templates/:contentTemplateId/edit",
    details: "/content-templates/:contentTemplateId",
  },
  targetAudiences: {
    create: "/target-audiences/create",
    list: "/target-audiences",
    edit: "/target-audiences/:targetAudienceId/edit",
    details: "/target-audiences/:targetAudienceId",
  },
  pricing: "/pricing",
  payment: {
    success: "/payment/success",
    error: "/payment/error",
    index: "/payment",
  },
  privacyPolicy: "/privacy-policy",
  terms: "/terms-of-use",
  export: "/strategies/:strategyId/export",
  account: {
    signIn: "/account/sign-in",
    emailSent: "/account/email-sent",
    confirmSignin: "/account/confirm-signin",
    settings: "/account/settings",
  },
  401: "/401",
  404: "/404",
  500: "/500",
};
