import { useState, type FC } from "react";
import { IconButton, Skeleton, Stack, SvgIcon, TextField } from "@mui/material";
import { StrategyPartType } from "src/types/strategy";
import { Card, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch, useSelector } from "src/store";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { GenerateAgain } from "../common/generate-again";
import EditIcon from "@mui/icons-material/Edit";
import { ContentEditor } from "../common/content-editor";
import { useStrategyPart } from "../hooks/use-strategy-part";

interface InstagramProfilePartPartProps {
  strategyId: string;
}

export const InstagramProfilePart: FC<InstagramProfilePartPartProps> = (
  props
) => {
  const { t } = useTranslation();

  const part = useStrategyPart(StrategyPartType.InstagramProfile);
  const { item } = useSelector((state) => state.strategy);

  const dispatch = useDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(item?.instagramTitle);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="h4">
          {t(tokens.recommendations.instagram.profile.header)}
        </Typography>

        <Stack
          direction={"row"}
          spacing={2}
        >
          {/* <IconButton
            size="small"
            disabled={part?.isGenerating || isEditing}
            onClick={createPart}
          >
            <SvgIcon>
              <RefreshIcon />
            </SvgIcon>
          </IconButton> */}

          <IconButton
            size="small"
            disabled={part?.item?.isGenerating || isEditing}
            onClick={() => {
              setIsEditing(!isEditing);
            }}
          >
            <SvgIcon>
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>

      {!part?.item?.isGenerating && !part?.item?.isReady && (
        <GenerateAgain
          createPart={part.createPart}
          error={t(tokens.recommendations.instagram.noDataFound)}
          isGenerating={part?.item?.isGenerating ?? false}
        />
      )}
      {(part?.item?.isReady || part?.item?.isGenerating) && (
        <Card>
          <CardContent>
            {!isEditing && (
              <>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {part?.item?.isReady && <>{item?.instagramTitle}</>}
                  {part?.item?.isGenerating && <Skeleton />}
                </Typography>
                {part?.item?.isReady && (
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: item?.instagramDescription ?? "",
                    }}
                  ></Typography>
                )}
              </>
            )}

            {isEditing && (
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  value={title}
                />
                <ContentEditor
                  onCancel={async () => {
                    setTitle(item?.instagramTitle);
                    setIsEditing(false);
                  }}
                  showHeaderTool={false}
                  text={item?.instagramDescription ?? ""}
                  onSave={async (content) => {
                    var newStrategy = { ...item };
                    newStrategy.instagramDescription = content;
                    newStrategy.instagramTitle = title;
                    await dispatch(
                      strategyThunks.updateStrategy(item!.id, newStrategy)
                    );
                    setIsEditing(false);
                  }}
                />
              </Stack>
            )}

            {part?.item?.isGenerating && (
              <>
                {[...Array(5)].map((_, index) => (
                  <Skeleton key={index} />
                ))}
              </>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};
