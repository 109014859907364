import { useCallback, useEffect, useRef, useState } from "react";
import { usePageView } from "src/hooks/use-page-view";
import { useDispatch, useSelector } from "src/store";
import type { Page as PageType } from "src/types/page";
import { thunks as productsThunks } from "src/thunks/products";
import { thunks as storylinesThunks } from "src/thunks/storylines";
import { thunks as targetAudiencesThunks } from "src/thunks/target-audiences";
import { thunks } from "src/thunks/strategy";
import { thunks as settingsThunks } from "src/thunks/settings";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BrandPart } from "src/sections/dashboard/profile/brand-part";
import { useTranslation } from "react-i18next";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
import { useSearchParams } from "src/hooks/use-search-params";
import { useParams } from "react-router";
import { CONSTS } from "src/consts";
import { ProductDetails } from "src/sections/dashboard/products/product-details";
import { TargetAudienceDetails } from "src/sections/dashboard/target-audiences/target-audience-details";
import { InstagramProfileDetails } from "src/sections/dashboard/instagram/instagram-profile-details";
import { FearsDetails } from "src/sections/dashboard/fears/fears-details";
import { StorylineDetails } from "src/sections/dashboard/storylines/storyline-details";
import ServerError from "src/sections/errors/server-error";
import { tokens } from "src/locales/tokens";

const StrategyContentRoot = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  "& h2": {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.h5.lineHeight,
    marginBottom: theme.spacing(3),
  },
  "& h6": {
    //fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    //lineHeight: theme.typography.h3.lineHeight,
    marginBottom: theme.spacing(2),
  },
  "& p": {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    // marginBottom: theme.spacing(2),
    // marginTop: theme.spacing(1),
  },
  "& li": {
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    marginBottom: theme.spacing(1),
  },
}));

const Page: PageType = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  useTranslation();

  usePageView();

  const searchParams = useSearchParams();
  const token = searchParams.get("token");
  const lang = searchParams.get("lang");

  const [isReady, setIsReady] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { strategyId } = useParams();
  const { i18n, t } = useTranslation();

  const { item: strategy } = useSelector((state) => state.strategy);
  const { items: storylines } = useSelector((state) => state.storylines);
  const { items: products } = useSelector((state) => state.products);
  const { items: targetAudiences } = useSelector(
    (state) => state.targetAudiences
  );

  const dispatch = useDispatch();
  const handleDataLoad = useCallback(async () => {
    try {
      if (strategyId && token) {
        localStorage.setItem(CONSTS.EXPORT_TOKEN_KEY, token);

        await dispatch(thunks.getStrategy(strategyId));
        await dispatch(settingsThunks.setStrategyId(strategyId));

        await dispatch(
          productsThunks.getProducts(strategyId!, {
            page: 1,
            top: 100,
          })
        );
        await dispatch(
          targetAudiencesThunks.getTargetAudiences(strategyId!, {
            page: 1,
            top: 100,
          })
        );
        await dispatch(
          storylinesThunks.getStorylines(strategyId!, {
            page: 1,
            top: 100,
          })
        );

        setTimeout(() => {
          setIsReady(true);
        }, 500);
      }
    } catch (err) {
      console.log(err);
      if (err?.code === "Profile.StrategyNotFound") {
        //router.push(paths[404]);
        setHasError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strategyId, token]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  useEffect(() => {
    handleDataLoad();
  }, [handleDataLoad]);

  if (hasError) {
    return (
      <>
        <ServerError showBackButton={false} />
        <Box id="isReady"></Box>
      </>
    );
  }

  if (!strategy) {
    return null;
  }

  return (
    <>
      <StrategyContentRoot>
        <Box
          component="main"
          ref={rootRef}
        >
          <Stack spacing={3}>
            <BrandPart isPrintingMode={true} />
            <Typography variant="h4">
              {t(tokens.export.sections.instagram)}
            </Typography>
            <Divider />
            <InstagramProfileDetails strategy={strategy!} />
            <Typography variant="h4">
              {t(tokens.export.sections.fears)}
            </Typography>
            <Divider />
            <FearsDetails strategy={strategy!} />
            <Typography variant="h4">
              {t(tokens.export.sections.products)}
            </Typography>
            <Divider />
            {products.items.map((product) => {
              return <ProductDetails product={product} />;
            })}
            {/*
                        <Typography variant="h4">
              {t(tokens.export.sections.storylines)}
            </Typography>
            <Divider />
            {storylines.items.map((storyline) => {
              return (
                <StorylineDetails
                  storyline={storyline}
                  isPrintingMode={true}
                />
              );
            })} */}
            <Typography variant="h4">
              {t(tokens.export.sections.targetAudiences)}
            </Typography>
            <Divider />
            {targetAudiences.items.map((targetAudience) => {
              return <TargetAudienceDetails targetAudience={targetAudience} />;
            })}
          </Stack>
        </Box>
        {isReady && <Box id="isReady"></Box>}
      </StrategyContentRoot>
    </>
  );
};

export default Page;
