import * as React from "react";
import type { EventContentArg } from "@fullcalendar/core";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ContentGoalType } from "src/types/content-goal-type";
import { Chip } from "@mui/material";

export interface EventContentProps extends EventContentArg {
  event: EventContentArg["event"] & {
    extendedProps: {
      description?: string;
      contentGoalTypeTitle?: string;
      priority?: "high" | "medium" | "low";
      contentGoalType: ContentGoalType;
      channelName?: string;
    };
  };
}

export const EventContent: React.FC<EventContentProps> = (props) => {
  const {
    priority = "low",
    contentGoalType,
    contentGoalTypeTitle,
    channelName,
  } = props.event.extendedProps;

  const color = "var(--mui-palette-primary-main)";

  const getContentTypeColor = (contentGoalType: ContentGoalType) => {
    switch (contentGoalType) {
      case ContentGoalType.Engagement:
        return "var(--mui-palette-primary-main)";
      case ContentGoalType.Community:
        return "var(--mui-palette-warning-main)";
      case ContentGoalType.Selling:
        return "var(--mui-palette-success-main)";
      // case ContentGoalType.Instruct:
      //   return "var(--mui-palette-error-main)";
      default:
        return "transparent";
    }
  };

  const getChipColor = (contentGoalType: ContentGoalType) => {
    switch (contentGoalType) {
      case ContentGoalType.Engagement:
        return "primary";
      case ContentGoalType.Community:
        return "warning";
      case ContentGoalType.Selling:
        return "success";
      // case ContentGoalType.Instruct:
      //   return "var(--mui-palette-error-main)";
      default:
        return "default";
    }
  };

  // const color =
  //   priority === "high"
  //     ? "var(--mui-palette-error-main)"
  //     : priority === "medium"
  //       ? "var(--mui-palette-warning-main)"
  //       : "transparent";

  return (
    <Paper
      sx={{
        border: "1px solid var(--mui-palette-divider)",
        boxShadow: "var(--mui-shadows-1)",
        borderRadius: 1,
        overflowX: "auto",
        overflowY: "hidden",
      }}
    >
      <Stack
        direction="row"
        spacing={0}
      >
        <Box
          sx={{
            bgcolor: getContentTypeColor(contentGoalType),
            flex: "0 0 auto",
            width: "4px",
          }}
        />
        <Stack
          sx={{ p: 1 }}
          spacing={1}
        >
          <Typography
            sx={{
              // overflow: "hidden",
              // textOverflow: "ellipsis",
              textWrap: "wrap",
            }}
            variant="body2"
          >
            {props.event.title.length ? props.event.title : "No topic"}
          </Typography>
          {contentGoalTypeTitle && (
            <Box>
              <Chip
                size="small"
                variant="outlined"
                color={getChipColor(contentGoalType)}
                label={
                  <Typography sx={{ fontSize: "0.9em" }}>
                    {contentGoalTypeTitle}
                  </Typography>
                }
              />
            </Box>
          )}
          {channelName && (
            <Box>
              <Chip
                size="small"
                variant="outlined"
                color={"default"}
                label={
                  <Typography sx={{ fontSize: "0.9em" }}>
                    {channelName}
                  </Typography>
                }
              />
            </Box>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};
