"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CaretUpDown as CaretUpDownIcon } from "@phosphor-icons/react/dist/ssr/CaretUpDown";

import { WorkspacesPopover } from "./workspaces-popover";
import { usePopover } from "src/hooks/use-popover";
import { useDispatch, useSelector } from "src/store";
import { thunks as settingsThunks } from "src/thunks/settings";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { CONSTS } from "src/consts";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

export function WorkspacesSwitch(): React.JSX.Element {
  const popover = usePopover<HTMLDivElement>();

  const { items } = useSelector((state) => state.strategies);
  const { strategyId } = useSelector((state) => state.settings);
  
  const strategy = items?.find((item) => item.id === strategyId);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (items.length < 2) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Stack
        direction="row"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        spacing={2}
        sx={{
          alignItems: "center",
          border: "1px solid var(--Workspaces-border-color)",
          borderRadius: "12px",
          cursor: "pointer",
          p: "4px 8px",
        }}
      >
        <Box sx={{ flex: "1 1 auto" }}>
          <Typography
            color="var(--Workspaces-title-color)"
            variant="caption"
          >
            {t(tokens.sidebar.workspaces.name)}
          </Typography>
          <Typography
            color="var(--Workspaces-name-color)"
            variant="subtitle2"
          >
            {strategy ? strategy.name : ""}
          </Typography>
        </Box>
        <CaretUpDownIcon
          color="var(--Workspaces-expand-color)"
          fontSize="var(--icon-fontSize-sm)"
        />
      </Stack>
      <WorkspacesPopover
        anchorEl={popover.anchorRef.current}
        onChange={async (id: string) => {
          popover.handleClose();
          await dispatch(settingsThunks.setStrategyId(id));
          await dispatch(strategyThunks.getStrategy(id));
          localStorage.setItem(CONSTS.STRATEGY_ID_KEY, id);
        }}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </React.Fragment>
  );
}
