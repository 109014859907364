import { tokens } from "../tokens";
//Organize content for your personal brand promotion with AI-powered content strategy generator
export const en = {
  [tokens.landing.title]: "MakeItLab - AI-powered content strategy generator",
  [tokens.landing.hero.title1]: "All-in-One Platform for building",
  [tokens.landing.hero.title2]: "your Personal Brand",
  [tokens.landing.hero.subtitle]:
    "Define your product, understand target audience, and create content that resonates with your followers",
  [tokens.landing.hero.button]: "Start for free",
  [tokens.landing.nav.strategies]: "Strategies",
  [tokens.landing.nav.singIn]: "Sign in",
  [tokens.landing.nav.singUp]: "Sign up",
  [tokens.landing.nav.privacyPolicy]: "Privacy policy",
  [tokens.landing.nav.termsOfUse]: "Terms of use",
  [tokens.landing.nav.panel]: "Dashboard",

  [tokens.nav.host]: "Host",
  [tokens.nav.profile]: "My profile",
  [tokens.nav.upgradeAccount]: "Upgrade plan",

  [tokens.sidebar.aboutMe]: "Profile",
  [tokens.sidebar.content]: "Content",
  [tokens.sidebar.ideas]: "Topics",
  [tokens.sidebar.leadMagnets]: "Lead magnets",
  [tokens.sidebar.contentTemplates]: "Content templates",
  [tokens.sidebar.topicGenerator]: "Topic generator",
  [tokens.sidebar.myBrand]: "My brand",
  [tokens.sidebar.targetAudiences]: "Target audiences",
  [tokens.sidebar.storylines]: "Storylines",
  [tokens.sidebar.fears]: "Fears",
  [tokens.sidebar.recommendations]: "Recommendations",
  [tokens.sidebar.product]: "Product",
  [tokens.sidebar.instagram]: "Instagram",
  [tokens.sidebar.general]: "General",
  [tokens.sidebar.settings]: "Settings",
  [tokens.sidebar.calendar]: "Calendar",
  [tokens.sidebar.workspaces.name]: "Strategy",

  [tokens.wizard.aiHeader]: "AI-powered options",
  [tokens.wizard.showExample]: "Show example",
  [tokens.wizard.placeholders.name]: "Your name",
  [tokens.wizard.placeholders.profession]: "Details of your background",
  [tokens.wizard.placeholders.skills]: "Your skill",
  [tokens.wizard.placeholders.goals]: "Your goal",
  [tokens.wizard.placeholders.productOrService.title]: "Title",
  [tokens.wizard.placeholders.productOrService.description]:
    "Short description",
  [tokens.wizard.placeholders.targetAudience.title]: "Title",
  [tokens.wizard.placeholders.targetAudience.description]: "Short description",
  [tokens.wizard.placeholders.valueProposition]: "Unique value proposition",
  [tokens.wizard.validators.maxItems]: "Max items count is ",

  [tokens.wizard.step.title]: "Strategy wizard",
  [tokens.wizard.signup.title]: "Sign up",
  [tokens.wizard.signup.text]: "Last step!",
  [tokens.wizard.signup.tooltip]:
    "Enter your email address and you will get a link to your strategy",
  [tokens.wizard.signup.changeEmailText]:
    "The e-mail with the link has been sent to the following address:",
  [tokens.wizard.signup.changeButtonText]: "Change email",
  [tokens.wizard.summary.title]: "Summary",
  [tokens.wizard.summary.text]: "Last step!",
  [tokens.wizard.summary.tooltip]:
    "I am ready to create a strategy based on the provided data",

  [tokens.recommendations.instagram.profile.header]: "Instagram profile",
  [tokens.recommendations.instagram.profile.title]: "Instagram profile",
  [tokens.recommendations.instagram.noDataFound]:
    "No Instagram profile data found",

  [tokens.recommendations.fears.header]: "Fears",
  [tokens.recommendations.fears.title]: "Fears",
  [tokens.recommendations.fears.noDataFound]: "No data found",

  [tokens.products.list.pageTitle]: "Products",
  [tokens.products.list.pageHeader]: "Products",
  [tokens.products.list.table.name]: "Name",
  [tokens.products.list.table.targetAudiences]: "Target audiences",
  [tokens.products.list.table.actions]: "Actions",
  [tokens.products.list.deleteProductDialogTitle]: "Delete product",
  [tokens.products.list.deleteProductDialogDescription]:
    "Are you sure you want to delete this product?",
  [tokens.products.list.buttons.add]: "New product",
  [tokens.products.list.noProductsFound]: "No products found",
  [tokens.products.list.errorLoadingProducts]: "Could not load products",
  [tokens.products.create.pageTitle]: "New product",
  [tokens.products.create.pageHeader]: "New product",
  [tokens.products.create.backButton]: "Back to products",
  [tokens.products.create.form.name]: "Title",
  [tokens.products.create.form.uniqueValueProposition]:
    "Unique value proposition",
  [tokens.products.create.form.description]: "Description",
  [tokens.products.create.form.successMessage]:
    "Product has been successfully created.",
  [tokens.products.details.pageTitle]: "Product details",
  [tokens.products.details.pageHeader]: "Product details",
  [tokens.products.details.backToProductsButton]: "Back to products",
  [tokens.products.details.form.name]: "Name",
  [tokens.products.details.form.uniqueValueProposition]:
    "Unique value proposition",
  [tokens.products.details.form.description]: "Description",
  [tokens.products.details.form.landingPage]: "Landing page",
  [tokens.products.details.form.targetAudiences]: "Target audiences",
  [tokens.products.details.form.successMessage]:
    "Product has been successfully updated.",

  [tokens.topics.list.pageTitle]: "Topics",
  [tokens.topics.list.pageHeader]: "Topics",
  [tokens.topics.list.tabs.generator]: "Topics generator",
  [tokens.topics.list.tabs.ideas]: "Topics",
  [tokens.topics.list.filters.text]: "Text",
  [tokens.topics.list.filters.storyline]: "Storyline",
  [tokens.topics.list.filters.product]: "Product",
  [tokens.topics.list.table.name]: "Name",
  [tokens.topics.list.table.product]: "Product",
  [tokens.topics.list.table.date]: "Date",
  [tokens.topics.list.table.storyline]: "Storyline",
  [tokens.topics.list.table.contentGoalType]: "Content goal",
  [tokens.topics.list.table.actions]: "Actions",
  [tokens.topics.list.deleteTopicDialogTitle]: "Delete topic",
  [tokens.topics.list.deleteTopicDialogDescription]:
    "Are you sure you want to delete this topic?",
  [tokens.topics.list.buttons.add]: "New topic",
  [tokens.topics.list.noTopicsFound]: "No topics found",
  [tokens.topics.list.errorLoadingTopics]: "Could not load topics",
  [tokens.topics.create.pageTitle]: "New topic",
  [tokens.topics.create.pageHeader]: "New topic",
  [tokens.topics.create.backButton]: "Back to topics",
  [tokens.topics.create.form.name]: "Title",
  [tokens.topics.create.form.description]: "Description",
  [tokens.topics.create.form.date]: "Publication date",
  [tokens.topics.create.form.successMessage]:
    "Topic has been successfully created.",
  [tokens.topics.details.pageTitle]: "Topic details",
  [tokens.topics.details.pageHeader]: "Topic details",
  [tokens.topics.details.backButton]: "Back to topics",
  [tokens.topics.details.form.name]: "Name",
  [tokens.topics.details.form.generationDialogHeader]: "AI content generation",
  [tokens.topics.details.form.template]: "Template",
  [tokens.topics.details.form.instruction]: "Instruction",
  [tokens.topics.details.form.date]: "Publication date",
  [tokens.topics.details.form.description]: "Description",
  [tokens.topics.details.form.content]: "Content",
  [tokens.topics.details.form.titleRequired]: "Title is required",
  [tokens.topics.details.form.generateButton]: "AI Template",
  [tokens.topics.details.form.updateSuccessMessage]:
    "Topic has been successfully updated.",
  [tokens.topics.details.form.createSuccessMessage]:
    "Topic has been successfully created.",
  [tokens.topics.generator.pageTitle]: "Topics generator",
  [tokens.topics.generator.pageHeader]: "Topics generator",
  [tokens.topics.generator.form.storyline]: "Storyline",
  [tokens.topics.generator.form.product]: "Product",
  [tokens.topics.generator.form.instruction]: "Instruction",
  [tokens.topics.generator.form.category]: "Category",
  [tokens.topics.generator.buttons.addTopic]: "Add",
  [tokens.topics.generator.buttons.removeTopic]: "Remove",
  [tokens.topics.generator.buttons.generateTopics]: "Generate topics",

  [tokens.leadMagnets.list.pageTitle]: "Lead magnets",
  [tokens.leadMagnets.list.pageHeader]: "Lead magnets",
  [tokens.leadMagnets.list.tabs.generator]: "Lead magnets generator",
  [tokens.leadMagnets.list.tabs.leadMagnets]: "Lead magnets",
  [tokens.leadMagnets.list.filters.text]: "Text",
  [tokens.leadMagnets.list.filters.product]: "Product",
  [tokens.leadMagnets.list.table.name]: "Name",
  [tokens.leadMagnets.list.table.type]: "Type",
  [tokens.leadMagnets.list.table.complexity]: "Complexity",
  [tokens.leadMagnets.list.table.product]: "Product",
  [tokens.leadMagnets.list.table.actions]: "Actions",
  [tokens.leadMagnets.list.deleteLeadMagnetDialogTitle]: "Delete lead magnet",
  [tokens.leadMagnets.list.deleteLeadMagnetDialogDescription]:
    "Are you sure you want to delete this lead magnet?",
  [tokens.leadMagnets.list.buttons.add]: "New lead magnet",
  [tokens.leadMagnets.list.noLeadMagnetsFound]: "No lead magnets found",
  [tokens.leadMagnets.list.errorLoadingLeadMagnets]:
    "Could not load lead magnets",
  [tokens.leadMagnets.create.pageTitle]: "New lead magnet",
  [tokens.leadMagnets.create.pageHeader]: "New lead magnet",
  [tokens.leadMagnets.create.backButton]: "Back to lead magnets",
  [tokens.leadMagnets.create.form.name]: "Title",
  [tokens.leadMagnets.create.form.description]: "Description",
  [tokens.leadMagnets.create.form.successMessage]:
    "Lead magnet has been successfully created.",
  [tokens.leadMagnets.details.pageTitle]: "Lead magnet details",
  [tokens.leadMagnets.details.pageHeader]: "Lead magnet details",
  [tokens.leadMagnets.details.backButton]: "Back to lead magnets",
  [tokens.leadMagnets.details.form.name]: "Name",
  [tokens.leadMagnets.details.form.description]: "Description",
  [tokens.leadMagnets.details.form.successMessage]:
    "Lead magnet has been successfully updated.",
  [tokens.leadMagnets.generator.pageTitle]: "Lead magnets generator",
  [tokens.leadMagnets.generator.pageHeader]: "Lead magnets generator",
  [tokens.leadMagnets.generator.form.product]: "Product",
  [tokens.leadMagnets.generator.form.instruction]: "Instruction",
  [tokens.leadMagnets.generator.buttons.addLeadMagnet]: "Save lead magnet",
  [tokens.leadMagnets.generator.buttons.removeLeadMagnet]: "Remove lead magnet",
  [tokens.leadMagnets.generator.buttons.generateLeadMagnets]:
    "Generate lead magnets",

  [tokens.storylines.list.pageTitle]: "Storylines",
  [tokens.storylines.list.pageHeader]: "Storylines",
  [tokens.storylines.list.table.name]: "Name",
  [tokens.storylines.list.table.contentGoal]: "Content goal",
  [tokens.storylines.list.table.actions]: "Actions",
  [tokens.storylines.list.deleteStorylineDialogTitle]: "Delete storyline",
  [tokens.storylines.list.deleteStorylineDialogDescription]:
    "Are you sure you want to delete this storyline?",
  [tokens.storylines.list.buttons.add]: "New storyline",
  [tokens.storylines.list.noStorylinesFound]: "No storylines found",
  [tokens.storylines.list.errorLoadingStorylines]: "Could not load storylines",
  [tokens.storylines.create.pageTitle]: "New storyline",
  [tokens.storylines.create.pageHeader]: "New storyline",
  [tokens.storylines.create.backButton]: "Back to storylines",
  [tokens.storylines.create.form.name]: "Title",
  [tokens.storylines.create.form.description]: "Description",
  [tokens.storylines.create.form.contentGoal]: "Content goal",
  [tokens.storylines.create.form.categories]: "Categories",
  [tokens.storylines.create.form.placeholders.name]: "Name",
  [tokens.storylines.create.form.placeholders.description]: "Description",
  [tokens.storylines.create.form.noCategories]: "No categories",
  [tokens.storylines.create.form.successMessage]:
    "Storyline has been successfully created.",
  [tokens.storylines.details.pageTitle]: "Storyline details",
  [tokens.storylines.details.pageHeader]: "Storyline details",
  [tokens.storylines.details.backButton]: "Back to storylines",
  [tokens.storylines.details.form.name]: "Name",
  [tokens.storylines.details.form.contentGoalType]: "Content goal",
  [tokens.storylines.details.form.description]: "Description",
  [tokens.storylines.details.form.placeholders.name]: "Name",
  [tokens.storylines.details.form.placeholders.description]: "Description",
  [tokens.storylines.details.form.categories]: "Categories",
  [tokens.storylines.details.form.noCategories]: "No categories",
  [tokens.storylines.details.form.noDescription]: "No description",
  [tokens.storylines.details.form.successMessage]:
    "Storyline has been successfully updated.",

  [tokens.contentPlaceholders.pageHeader]: "Topic",
  [tokens.contentPlaceholders.form.name]: "Title",
  [tokens.contentPlaceholders.form.description]: "Description",
  [tokens.contentPlaceholders.form.contentGoal]: "Content goal",
  [tokens.contentPlaceholders.form.channel]: "Channel",
  [tokens.contentPlaceholders.form.date]: "Date",
  [tokens.contentPlaceholders.form.content]: "Content",
  [tokens.contentPlaceholders.options.newTopic]: "Write a topic from scratch",
  [tokens.contentPlaceholders.options.selectTopic]: "Select from saved topics",
  [tokens.contentPlaceholders.options.generateTopic]: "Generate a topic",
  [tokens.contentPlaceholders.deleteContentPlaceholderDialogDescription]:
    "Are you sure you want to delete this item?",
  [tokens.contentPlaceholders.deleteContentPlaceholderDialogTitle]:
    "Delete item",

  [tokens.storylinesCategories.details.form.topics]: "Topics",
  [tokens.storylinesCategories.details.form.noTopics]: "No topics",
  [tokens.storylinesCategories.details.form.addTopic]: "Add topic",
  [tokens.storylinesCategories.details.form.generateTopics]: "Generate topics",
  [tokens.storylinesCategories.details.form.topicUpdatedMessage]:
    "Topic updated",
  [tokens.storylinesCategories.details.form.topicCreatedMessage]:
    "Topic created",
  [tokens.storylinesCategories.details.form.categoryUpdateMessage]:
    "Category updated",
  [tokens.storylinesCategories.details.deleteTopicDialogTitle]: "Delete topic",
  [tokens.storylinesCategories.details.deleteTopicDialogDescription]:
    "Are you sure you want to delete this topic?",
  [tokens.storylinesCategories.details.deleteCategoryDialogTitle]:
    "Delete category",
  [tokens.storylinesCategories.details.deleteCategoryDialogDescription]:
    "Are you sure you want to delete this category?",

  [tokens.calendar.contentPlanSchedule.pageHeader]: "Content planning",
  [tokens.calendar.generateTopic.pageHeader]: "Generate topics",
  [tokens.calendar.selectTopic.pageHeader]: "Select a topic",
  [tokens.calendar.selectTopic.errorLoadingTopics]: "Could not load topics",
  [tokens.calendar.selectTopic.noTopicsFound]: "No topics found",

  [tokens.contentTemplates.list.pageHeader]: "Content templates",
  [tokens.contentTemplates.list.table.name]: "Name",
  [tokens.contentTemplates.list.table.actions]: "Actions",
  [tokens.contentTemplates.list.deleteContentTemplateDialogTitle]:
    "Delete content template",
  [tokens.contentTemplates.list.deleteContentTemplateDialogDescription]:
    "Are you sure you want to delete this content template?",
  [tokens.contentTemplates.list.buttons.add]: "New content template",
  [tokens.contentTemplates.list.noContentTemplatesFound]:
    "No content templates found",
  [tokens.contentTemplates.list.errorLoadingContentTemplates]:
    "Could not load content templates",
  [tokens.contentTemplates.create.pageTitle]: "New content template",
  [tokens.contentTemplates.create.pageHeader]: "New content template",
  [tokens.contentTemplates.create.backButton]: "Back to content templates",
  [tokens.contentTemplates.create.form.name]: "Title",
  [tokens.contentTemplates.create.form.instruction]: "Instruction",
  [tokens.contentTemplates.create.form.examples]: "Examples",
  [tokens.contentTemplates.create.form.noExamples]: "No examples",
  [tokens.contentTemplates.create.form.successMessage]:
    "Content template has been successfully created.",
  [tokens.contentTemplates.details.pageTitle]: "Content template details",
  [tokens.contentTemplates.details.pageHeader]: "Content template details",
  [tokens.contentTemplates.details.backButton]: "Back to content templates",
  [tokens.contentTemplates.details.form.name]: "Name",
  [tokens.contentTemplates.details.form.instruction]: "Instruction",
  [tokens.contentTemplates.details.form.examples]: "Examples",
  [tokens.contentTemplates.details.form.noExamples]: "No examples",
  [tokens.contentTemplates.details.form.successMessage]:
    "Content template has been successfully updated.",

  [tokens.targetAudiences.list.pageTitle]: "Target Audiences",
  [tokens.targetAudiences.list.pageHeader]: "Target Audiences",
  [tokens.targetAudiences.list.table.name]: "Name",
  [tokens.targetAudiences.list.table.products]: "Products",
  [tokens.targetAudiences.list.table.actions]: "Actions",
  [tokens.targetAudiences.list.deleteTargetAudienceDialogTitle]:
    "Delete target audience",
  [tokens.targetAudiences.list.deleteTargetAudienceDialogDescription]:
    "Are you sure you want to delete this target audience?",
  [tokens.targetAudiences.list.buttons.add]: "New target audience",
  [tokens.targetAudiences.list.noTargetAudiencesFound]:
    "No target audience found",
  [tokens.targetAudiences.list.errorLoadingTargetAudiences]:
    "Could not load target audiences",
  [tokens.targetAudiences.create.pageTitle]: "New target audience",
  [tokens.targetAudiences.create.pageHeader]: "New target audience",
  [tokens.targetAudiences.create.backButton]: "Back to target audiences",
  [tokens.targetAudiences.create.form.name]: "Name",
  [tokens.targetAudiences.create.form.description]: "Description",
  [tokens.targetAudiences.create.form.content]: "Content",
  [tokens.targetAudiences.create.form.products]: "Products",
  [tokens.targetAudiences.create.form.successMessage]:
    "Target audience has been successfully created.",
  [tokens.targetAudiences.details.sections.goals]: "Goals",
  [tokens.targetAudiences.details.sections.pains]: "Pains",
  [tokens.targetAudiences.edit.pageTitle]: "Target audience details",
  [tokens.targetAudiences.edit.pageHeader]: "Target audience details",
  [tokens.targetAudiences.edit.backToTargetAudiencessButton]:
    "Back to target audiences",
  [tokens.targetAudiences.edit.form.name]: "Name",
  [tokens.targetAudiences.edit.form.description]: "Description",
  [tokens.targetAudiences.edit.form.idealCustomer]: "Ideal customer",
  [tokens.targetAudiences.edit.form.pains]: "Pains",
  [tokens.targetAudiences.edit.form.goals]: "Goals",
  [tokens.targetAudiences.edit.form.products]: "Products",
  [tokens.targetAudiences.edit.form.successMessage]:
    "Target audience has been successfully updated.",

  [tokens.aboutMe.header]: "Profile",
  [tokens.aboutMe.title]: "Profile",
  [tokens.aboutMe.noAboutMeFound]: "No data found",
  [tokens.aboutMe.sections.aboutMe]: "About me",
  [tokens.aboutMe.sections.mission]: "Mission",
  [tokens.aboutMe.sections.goals]: "Goals",
  [tokens.aboutMe.sections.values]: "Values",
  [tokens.aboutMe.sections.goalsPriority]: "Goals priority",
  [tokens.aboutMe.sections.skills]: "Skills",
  [tokens.aboutMe.sections.productsOrServices]: "My plan to reach the goals",
  [tokens.aboutMe.sections.productOrService]: "I will start with",
  [tokens.aboutMe.sections.positionings]: "Positioning",
  [tokens.aboutMe.sections.voice]: "Values",
  [tokens.aboutMe.sections.expertContent]: "Professional content",
  [tokens.aboutMe.sections.personalContent]: "Personal content",
  [tokens.aboutMe.sections.contentVision]: "Content vision",
  [tokens.aboutMe.sections.channels]: "Communication channels",
  [tokens.aboutMe.sections.strengths]: "Strengths",
  [tokens.aboutMe.sections.slogan]: "Slogan",

  [tokens.fears.title]: "Fears",
  [tokens.fears.header]: "Fears",
  [tokens.fears.errorLoading]: "Coudn't load fears",
  [tokens.fears.noFearsFound]: "No fears found",

  [tokens.strategySettings.title]: "Settings",
  [tokens.strategySettings.header]: "Settings",
  [tokens.strategySettings.export.header]: "Export strategy",
  [tokens.strategySettings.export.explanation]:
    "Create a PDF file. It may take a while to generate it.",
  [tokens.strategySettings.buttons.export]: "Export strategy",
  [tokens.strategySettings.buttons.delete]: "Delete strategy",
  [tokens.strategySettings.buttons.upgrade]: "Upgrade plan",
  [tokens.strategySettings.form.name]: "Name",
  [tokens.strategySettings.form.successMessage]: "Settings have been saved",
  [tokens.strategySettings.tabs.general]: "General",
  [tokens.strategySettings.tabs.channels]: "Channels",
  [tokens.strategySettings.errorLoading]: "Coudn't load settings",
  [tokens.strategySettings.noStrategySettingsFound]: "No settings found",
  [tokens.strategySettings.detele.header]: "Delete strategy",
  [tokens.strategySettings.detele.explanation]:
    "After deleting the strategy, you will not be able to restore it.",
  [tokens.strategySettings.detele.deleteStrategyDialogTitle]: "Delete strategy",
  [tokens.strategySettings.detele.deleteStrategyDialogDescription]:
    "Are you sure you want to delete the strategy?",
  [tokens.strategySettings.plan.header]: "Plan",
  [tokens.strategySettings.plan.status]: "Status",
  [tokens.strategySettings.plan.active]: "Active",
  [tokens.strategySettings.plan.currentPlan]: "Current plan",

  [tokens.channels.form.noChannels]: "No channels",
  [tokens.channels.form.successMessage]: "Channels have been updated",
  [tokens.channels.errorLoadingChannels]: "Could not load channels",

  [tokens.export.sections.instagram]: "Instagram",
  [tokens.export.sections.fears]: "Fears",
  [tokens.export.sections.storylines]: "Storylines",
  [tokens.export.sections.products]: "Products",
  [tokens.export.sections.targetAudiences]: "Target audiences",

  [tokens.payment.statuses.approved]: "Approved",
  [tokens.payment.statuses.pending]: "Pending",
  [tokens.payment.statuses.declined]: "Declined",
  [tokens.payment.statuses.unknown]: "Unknown",
  [tokens.payment.statuses.refunded]: "Refunded",
  [tokens.payment.statuses.noPayment]: "No payment",
  [tokens.payment.success.header]: "Payment details",
  [tokens.payment.success.title]: "Payment details",
  [tokens.payment.success.product]: "Plan",
  [tokens.payment.success.openDashboardButton]: "Open dashboard",
  [tokens.payment.success.email]: "Email",
  [tokens.payment.success.status]: "Status",
  [tokens.payment.success.orderReference]: "Order reference",
  [tokens.payment.success.messages.approved]:
    "You has payment has been successfully processed.",
  [tokens.payment.success.messages.pending]:
    "Once the payment is approved, you will get full accesss to the product.",
  [tokens.payment.success.messages.declined]:
    "The payment has been declined. Please try again or contact support.",
  [tokens.payment.success.messages.unknown]:
    "The payment status is unknown. Please contact support.",

  [tokens.payment.error.header]: "Payment error",
  [tokens.payment.error.title]: "Payment error",
  [tokens.payment.error.message]:
    "Your payment has not been processed. Please try again.<br/><br/>If the problem persists, contact support.",
  [tokens.payment.error.orderReference]: "Order reference",
  [tokens.payment.error.unknownStatus]: "Unknown status",

  [tokens.account.signUp.title]: "Create account",
  [tokens.account.signUp.header]: "Create account",
  [tokens.account.signUp.form.firstName]: "First name *",
  [tokens.account.signUp.form.lastName]: "Last name *",
  [tokens.account.signUp.form.phone]: "Phone number *",
  [tokens.account.signUp.form.email]: "Email address *",
  [tokens.account.signUp.form.password]: "Password *",
  [tokens.account.signUp.form.createAccountButton]: "Create account",
  [tokens.account.signUp.form.termsOfUseLink]: "Terms of use",
  [tokens.account.signUp.form.privacyPolicyLink]: "Privacy Policy",
  [tokens.account.signUp.form.andSeparator]: "and",
  [tokens.account.signUp.form.agreementText]: "I have read the",
  [tokens.account.signUp.form.signinLink]: "Sign in",
  [tokens.account.signUp.form.hasAccount]: "Already have an account?",

  [tokens.account.signIn.title]: "Sign in",
  [tokens.account.signIn.header]: "Sign in to MakeItLab",
  [tokens.account.signIn.form.email]: "Email address *",
  [tokens.account.signIn.form.signInButton]: "Sign in",
  [tokens.account.signIn.form.termsOfUseLink]: "Terms of use",
  [tokens.account.signIn.form.privacyPolicyLink]: "Privacy Policy",
  [tokens.account.signIn.form.andSeparator]: "and",
  [tokens.account.signIn.form.agreementText]: "I have read the",

  [tokens.account.confirmSignIn.title]: "Confirm sign in",
  [tokens.account.confirmSignIn.expiredToken]:
    "The link has expired. Please sign in again.",
  [tokens.account.confirmSignIn.strategyIsFinished]:
    "The strategy has been already finished",

  [tokens.account.emailSent.title]: "Email sent",
  [tokens.account.emailSent.header]: "Email sent",
  [tokens.account.emailSent.message]:
    "The email with the sign in link has been sent. Please check your mailbox.",

  [tokens.account.resetPassword.title]: "Reset password",
  [tokens.account.resetPassword.header]: "Reset password",
  [tokens.account.resetPassword.signInLink]: "Sign in",
  [tokens.account.resetPassword.form.passwordChangedMessage]:
    "Your password has been successfully changed. Go to the sign in page.",
  [tokens.account.resetPassword.form.password]: "Password *",
  [tokens.account.resetPassword.form.confirmPassword]: "Confirm password *",
  [tokens.account.resetPassword.form.resetButton]: "Reset password",
  [tokens.account.resetPassword.form.linkInvalidError]:
    "Invalid link. Please try to reset your password again",
  [tokens.account.resetPassword.form.linkInvalidOrExpiredError]:
    "Restore password link has expired or invalid. Try to reset a password again",

  [tokens.account.forgotPassword.title]: "Forgot password",
  [tokens.account.forgotPassword.header]: "Forgot password",
  [tokens.account.forgotPassword.signInLink]: "Sign in",
  [tokens.account.forgotPassword.form.email]: "Email address *",
  [tokens.account.forgotPassword.form.emailSentMessage]:
    "The email with the link has been sent. Please check your mailbox.",
  [tokens.account.forgotPassword.form.resetButton]: "Send a reset link",

  [tokens.account.popover.account]: "Account settings",
  [tokens.account.popover.impersonate]: "Impersonate",
  [tokens.account.popover.stopImpersonation]: "Stop impersonation",
  [tokens.account.popover.signOut]: "Sign out",

  [tokens.account.colorScheme.light]: "Light",
  [tokens.account.colorScheme.dark]: "Dark",
  [tokens.account.colorScheme.scheme]: "Color scheme",

  [tokens.account.profile.title]: "Account",
  [tokens.account.profile.header]: "Account",
  [tokens.account.profile.passwordHeader]: "Password",
  [tokens.account.profile.accountDetailsHeader]: "Account details",
  [tokens.account.profile.accountProfileImage]: "Profile image",
  [tokens.account.profile.tab.general]: "General",
  [tokens.account.profile.form.firstName]: "First name *",
  [tokens.account.profile.form.lastName]: "Last name *",
  [tokens.account.profile.form.email]: "Email address *",
  [tokens.account.profile.form.currentPassword]: "Current password *",
  [tokens.account.profile.form.newPassword]: "New password *",
  [tokens.account.profile.form.confirmPassword]: "Confirm password *",
  [tokens.account.profile.form.passwordUpdatedMessage]:
    "Your password has been successfully updated.",
  [tokens.account.profile.form.accountUpdatedMessage]:
    "Your account has been successfully updated.",

  [tokens.account.impersonate.form.header]: "Impersonate as a user",
  [tokens.account.impersonate.form.searchForUser]: "User",
  [tokens.account.impersonate.form.buttons.impersonate]: "Impersonate",

  [tokens.contact.title]: "Demo",
  [tokens.contact.header]: "Schedule MakeItLab demo",
  [tokens.contact.subHeader]:
    "Our friendly experts will show you MakeItLab and customize it to your needs.",
  [tokens.contact.form.firstName]: "First name *",
  [tokens.contact.form.lastName]: "Last name *",
  [tokens.contact.form.phone]: "Phone number *",
  [tokens.contact.form.email]: "Email address *",
  [tokens.contact.form.message]: "Message *",
  [tokens.contact.form.sendButton]: "Send message",
  [tokens.contact.form.agreementText]: "By submitting this, you agree to the",
  [tokens.contact.form.termsOfUseLink]: "Terms of use",
  [tokens.contact.form.privacyPolicyLink]: "Privacy Policy",
  [tokens.contact.form.andSeparator]: "and",
  [tokens.contact.form.successTitle]: "Your message has been sent",
  [tokens.contact.form.successMessage1]: "We will contact you soon.",
  [tokens.contact.form.successMessage2]:
    "Thank you for your interest in MakeItLab!",

  [tokens.errors.serverError.title]: "Service is unavailable",
  [tokens.errors.serverError.header]: "Service is unavailable",
  [tokens.errors.serverError.message]:
    "There might be ongoing maintenance or a temporary overload.",
  [tokens.errors.notFound.title]: "Not found",
  [tokens.errors.notFound.header]: "Not found",
  [tokens.errors.notFound.message]:
    "The page you are looking for does not exist or has been deleted.",

  [tokens.general.languageChanged]: "Language changed",
  [tokens.general.language]: "Language",
  [tokens.general.newAppVersion.description]: "A new version is available.",
  [tokens.general.newAppVersion.action]: "Update",

  [tokens.general.placeholders.editor]: "Type your text here...",

  [tokens.general.paginationRowsPerPage]: "Items:",
  [tokens.general.filters.emptyOptions]: "No options available",
  [tokens.general.formError]: "Something went wrong. Try Again!",
  [tokens.general.loading]: "Loading...",

  [tokens.general.validators.user.conflict]:
    "The user with such an email is already added",
  [tokens.general.validators.employee.conflict]:
    "The employee is already added",
  [tokens.general.validators.employee.accountConnected]:
    "The email is already connected to another employee",
  [tokens.general.validators.role.required]: "Role is required",
  [tokens.general.validators.role.min]: "At least one role is required",
  [tokens.general.validators.password.minLength]:
    "Password must be at least 6 characters",
  [tokens.general.validators.passwordConfirm.notMatch]:
    "Passwords do not match",
  [tokens.general.validators.policy.required]: "This field must be checked",
  [tokens.general.validators.invalidEmail]: "Invalid email format",
  [tokens.general.validators.roleRequired]: "At least one role is required",
  [tokens.general.validators.maxLength255]:
    "Field must be at most 255 characters",
  [tokens.general.validators.maxLength50]:
    "Field must be at most 50 characters",
  [tokens.general.validators.maxLength1000]:
    "Field must be at most 1000 characters",
  [tokens.general.validators.required]: "Required field",
  [tokens.general.validators.oneItemRequired]: "At least one item is required",

  [tokens.general.filepicker.titleHighlight]: "Click to upload",
  [tokens.general.filepicker.titleNormal]: " or drag and drop",
  [tokens.general.filepicker.imageExtensions]: "(JPG or PNG), 5MB max",
  [tokens.general.filepicker.excelExtensions]: "(XLS, or XLSX)",
  [tokens.general.filepicker.fileSizeError]: "File size must be less than 5MB",
  [tokens.general.filepicker.deleteImage]: "Delete",
  [tokens.general.filepicker.imageUploaded]: "Image has been uploaded",
  [tokens.general.actions.delete]: "Delete",
  [tokens.general.actions.edit]: "Edit",
  [tokens.general.aiGeneration.pageHeader]: "Content generation",
  [tokens.general.plans.basic]: "Basic",
  [tokens.general.plans.professional]: "Professional",
  [tokens.general.plans.free]: "Free",
  [tokens.general.contentGoalTypes.notSelected]: "Not selected",
  [tokens.general.contentGoalTypes.engagement]: "Engagement",
  [tokens.general.contentGoalTypes.community]: "Build community",
  [tokens.general.contentGoalTypes.selling]: "Selling",
  [tokens.general.complexity.unknown]: "Unknown",
  [tokens.general.complexity.easy]: "Easy",
  [tokens.general.complexity.medium]: "Medium",
  [tokens.general.complexity.complicated]: "Complicated",
  [tokens.general.buttons.ok]: "Ok",
  [tokens.general.buttons.delete]: "Delete",
  [tokens.general.buttons.edit]: "Edit",
  [tokens.general.buttons.add]: "Add",
  [tokens.general.buttons.copy]: "Copy",
  [tokens.general.buttons.invite]: "Invite",
  [tokens.general.buttons.update]: "Update",
  [tokens.general.buttons.save]: "Save",
  [tokens.general.buttons.archive]: "Archive",
  [tokens.general.buttons.create]: "Create",
  [tokens.general.buttons.generate]: "Generate",
  [tokens.general.buttons.cancel]: "Cancel",
  [tokens.general.buttons.close]: "Close",
  [tokens.general.buttons.loadMore]: "Load more",
  [tokens.general.buttons.more]: "More",
  [tokens.general.buttons.tryAgain]: "Try again",
  [tokens.general.buttons.backToHome]: "Back to home page",
  [tokens.general.buttons.addItem]: "Add",
  [tokens.general.buttons.next]: "Next →",
  [tokens.general.buttons.back]: "Back",
  [tokens.general.buttons.goBack]: "Go back",
  [tokens.general.buttons.generateAgain]: "Generate again",
  [tokens.general.buttons.generateStrategy]: "Generate strategy",
  [tokens.general.buttons.finish]: "Finish",
};
