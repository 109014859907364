import type { AppThunk } from "src/store";
import { fetchWrapper } from "src/utils/fetch-wrapper";
import { api } from "src/config";
import { slice as channelsSlice } from "src/slices/channels";

const getChannels =
  (strategyId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(channelsSlice.actions.setLoadingStatus(true));
    dispatch(channelsSlice.actions.setErrorStatus(false));
    try {
      var channels = await fetchWrapper.get(
        `${api}/strategies/${strategyId}/channels`,
        {}
      );
      dispatch(channelsSlice.actions.getChannels(channels));
    } catch (error) {
      dispatch(channelsSlice.actions.setErrorStatus(true));
    }
    dispatch(channelsSlice.actions.setLoadingStatus(false));
  };

const updateChannels =
  (strategyId: string, value: any): AppThunk =>
  async (dispatch): Promise<void> => {
    var items = await fetchWrapper.put(
      `${api}/strategies/${strategyId}/channels`,
      value
    );
    dispatch(channelsSlice.actions.getChannels(items));
    return items;
  };

export const thunks = {
  getChannels,
  updateChannels,
};
