import type { FC } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FieldArray, Form, Formik, getIn } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { thunks } from "src/thunks/channels";
import { useDispatch, useSelector } from "src/store";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useCallback, useEffect } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { LoadIndicator } from "../common/load-indicator";
import { ErrorMessage } from "../common/error-message";

export const StrategyChannels: FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { strategyId } = useSelector((state) => state.settings);
  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.channels
  );

  const loadChannels = useCallback(async () => {
    await dispatch(thunks.getChannels(strategyId!));
  }, [dispatch, strategyId]);

  useEffect(() => {
    loadChannels();
  }, [loadChannels]);

  if (isLoading) {
    return <LoadIndicator />;
  }

  if (hasLoadingError) {
    return (
      <Card>
        <CardContent>
          <ErrorMessage text={t(tokens.channels.errorLoadingChannels)} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        channels: items,
        submit: null,
      }}
      onSubmit={async (values, helpers): Promise<void> => {
        try {
          await dispatch(
            thunks.updateChannels(strategyId!, {
              channels: values.channels,
            })
          );
          toast.success(t(tokens.channels.form.successMessage));
        } catch (error) {
          toast.error(t(tokens.general.formError));
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      }}
      validationSchema={Yup.object().shape({
        channels: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .required(t(tokens.general.validators.required) as string)
              .max(255, t(tokens.general.validators.maxLength255) as string),
          })
        ),
      })}
    >
      {(props) => (
        <>
          <Form placeholder={""}>
            <Stack spacing={4}>
              <Card sx={{ display: "flex", flexDirection: "column" }}>
                {props.values.channels.length === 0 && (
                  <>
                    <CardContent>
                      <Typography variant="body2">
                        {t(tokens.channels.form.noChannels)}
                      </Typography>
                    </CardContent>
                    <Divider />
                  </>
                )}
                <Box sx={{ overflowX: "auto" }}>
                  <List disablePadding>
                    <FieldArray
                      name="channels"
                      render={(arrayHelpers) => (
                        <>
                          {props.values.channels.map((channel, index) => {
                            const name = `channels[${index}].name`;
                            const touchedName = getIn(props.touched, name);
                            const errorName = getIn(props.errors, name);

                            return (
                              <ListItem
                                key={index}
                                divider
                                sx={{ p: 2, width: "100%" }}
                              >
                                <Stack
                                  spacing={1}
                                  direction={{
                                    xs: "column",
                                    sm: "row",
                                  }}
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <TextField
                                    error={!!(touchedName && errorName)}
                                    fullWidth
                                    helperText={touchedName && errorName}
                                    name={name}
                                    onBlur={props.handleBlur}
                                    onChange={props.handleChange}
                                    value={channel.name}
                                  />

                                  <IconButton
                                    onClick={() => {
                                      arrayHelpers.remove(index);
                                    }}
                                  >
                                    <SvgIcon>
                                      <DeleteOutlinedIcon color="error" />
                                    </SvgIcon>
                                  </IconButton>
                                </Stack>
                              </ListItem>
                            );
                          })}

                          <Box sx={{ p: 2 }}>
                            <Button
                              onClick={() =>
                                arrayHelpers.push({ id: "", name: "" })
                              }
                              variant="outlined"
                              color="primary"
                            >
                              {t(tokens.general.buttons.add)}
                            </Button>
                          </Box>
                        </>
                      )}
                    />
                  </List>
                </Box>
              </Card>

              <Stack
                alignItems="center"
                direction="row-reverse"
                justifyContent="flex-end"
                spacing={1}
              >
                <LoadingButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};
