import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Channel } from "src/types/channel";

interface ChannelsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: Channel[];
}

const initialState: ChannelsState = {
  isLoading: false,
  hasLoadingError: false,
  items: [],
};

const reducers = {
  setLoadingStatus: (
    state: ChannelsState,
    action: PayloadAction<boolean>
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: ChannelsState,
    action: PayloadAction<boolean>
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getChannels: (
    state: ChannelsState,
    action: PayloadAction<Channel[]>
  ): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: "channels",
  initialState,
  reducers,
});

export const { reducer } = slice;
