import { useCallback, useEffect } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/storylines";
import { StrategyPartType } from "src/types/strategy";
import { GenerateAgain } from "src/sections/dashboard/common/generate-again";
import { useStrategyPart } from "src/sections/dashboard/hooks/use-strategy-part";
import { StorylineList } from "src/sections/dashboard/storylines/storyline-list";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const part = useStrategyPart(StrategyPartType.Storylines);

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.storylines
  );

  const loadStorylines = useCallback(
    async (page: number, top: number) => {
      await dispatch(
        thunks.getStorylines(strategyId!, {
          page: page,
          top: top,
        })
      );
    },
    [dispatch, strategyId]
  );

  useEffect(() => {
    loadStorylines(items.page, items.top);
  }, [items.page, items.top, loadStorylines, part?.item?.isReady]);

  return (
    <>
      <Seo title={t(tokens.storylines.list.pageTitle) as string} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t(tokens.storylines.list.pageHeader)}
              </Typography>
            </Stack>
            {/* <IconButton
              size="small"
              disabled={part?.item?.isGenerating}
              onClick={part.createPart}
            >
              <SvgIcon>
                <RefreshOutlinedIcon />
              </SvgIcon>
            </IconButton> */}
            {/* {part?.item?.isReady && (
              <Button
                component={RouterLink}
                href={paths.storylines.create}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(tokens.storylines.list.buttons.add)}
              </Button>
            )} */}
          </Stack>

          {!part?.item?.isGenerating && !part?.item?.isReady && (
            <GenerateAgain
              createPart={part.createPart}
              error={t(tokens.storylines.list.noStorylinesFound)}
              isGenerating={part?.item?.isGenerating ?? false}
            />
          )}

          {part?.item?.isGenerating && (
            <>
              <Card>
                <CardContent>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </CardContent>
              </Card>
            </>
          )}
          {part?.item?.isReady && (
            <StorylineList
              items={items.items}
              isLoading={isLoading}
              hasError={hasLoadingError}
              onReset={() => loadStorylines(1, items.top)}
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Page;
