import { useCallback, useEffect } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/target-audiences";
import { TargetAudienceList } from "src/sections/dashboard/target-audiences/target-audience-list";
import { GenerateAgain } from "src/sections/dashboard/common/generate-again";
import { StrategyPartType } from "src/types/strategy";
import { useStrategyPart } from "src/sections/dashboard/hooks/use-strategy-part";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const part = useStrategyPart(StrategyPartType.TargetAudiences);

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.targetAudiences
  );

  const loadTargetAudiences = useCallback(
    async (page: number, top: number) => {
      await dispatch(
        thunks.getTargetAudiences(strategyId!, {
          page: page,
          top: top,
        })
      );
    },
    [dispatch, strategyId]
  );

  useEffect(() => {
    loadTargetAudiences(items.page, items.top);
  }, [items.page, items.top, loadTargetAudiences, part?.item?.isReady]);

  return (
    <>
      <Seo title={t(tokens.targetAudiences.list.pageTitle) as string} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t(tokens.targetAudiences.list.pageHeader)}
              </Typography>
            </Stack>

            {/* <IconButton
              size="small"
              disabled={part?.item?.isGenerating}
              onClick={part.createPart}
            >
              <SvgIcon>
                <RefreshOutlinedIcon />
              </SvgIcon>
            </IconButton> */}
            {/* {part?.isReady && (
              <Button
                component={RouterLink}
                href={paths.targetAudiences.create}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(tokens.targetAudiences.list.buttons.add)}
              </Button>
            )} */}
          </Stack>

          {!part?.item?.isGenerating && !part?.item?.isReady && (
            <GenerateAgain
              createPart={part.createPart}
              error={t(tokens.targetAudiences.list.noTargetAudiencesFound)}
              isGenerating={part?.item?.isGenerating ?? false}
            />
          )}

          {part?.item?.isGenerating && (
            <>
              <Card>
                <CardContent>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </CardContent>
              </Card>
            </>
          )}

          {part?.item?.isReady && (
            <TargetAudienceList
              items={items.items}
              onReset={() => loadTargetAudiences(1, items.top)}
              isLoading={isLoading}
              hasError={hasLoadingError}
            />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Page;
