import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Skeleton,
  Stack,
  SvgIcon,
  Tab,
  Typography,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/topics";
import React from "react";
import { GenerateAgain } from "src/sections/dashboard/common/generate-again";
import { StrategyPartType } from "src/types/strategy";
import { GeneratedTopic } from "src/types/topic";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { useStrategyPart } from "src/sections/dashboard/hooks/use-strategy-part";
import {
  TopicFilters,
  TopicListFilters,
} from "src/sections/dashboard/topics/topic-list-filters";
import { TopicListTable } from "src/sections/dashboard/topics/topic-list-table";
import { TopicGenerationForm } from "src/sections/dashboard/topics/topic-generation-form";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const [currentTab, setCurrentTab] = useState<string>("topics");

  const tabs = useMemo(
    () => [
      {
        label: t(tokens.topics.list.tabs.ideas),
        value: "topics",
      },
      {
        label: t(tokens.topics.list.tabs.generator),
        value: "generator",
      },
    ],
    [t]
  );

  const handleTabsChange = useCallback(
    (event: ChangeEvent<{}>, value: string): void => {
      setCurrentTab(value);
    },
    []
  );

  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);

  const part = useStrategyPart(StrategyPartType.Storylines);

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.topics
  );

  const [filters, setFilters] = React.useState<TopicFilters>({
    text: "",
    product: null,
    storyline: null,
  });

  const loadTopics = useCallback(
    async (page: number, top: number) => {
      await dispatch(
        thunks.getTopics(strategyId!, {
          page: page,
          top: top,
          text: filters.text ?? "",
          productId: filters.product?.id ?? "",
          storyLineId: filters.storyline?.id ?? "",
          lastItemDateTime: ""
        })
      );
    },
    [
      dispatch,
      filters.product?.id,
      filters.storyline?.id,
      filters.text,
      strategyId,
    ]
  );

  useEffect(() => {
    loadTopics(items.page, items.top);
  }, [items.page, items.top, loadTopics, part?.item?.isReady]);

  return (
    <>
      <Seo title={t(tokens.topics.list.pageTitle) as string} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t(tokens.topics.list.pageHeader)}
              </Typography>
            </Stack>
            {part?.item?.isReady && (
              <Button
                component={RouterLink}
                href={paths.topics.create}
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                {t(tokens.topics.list.buttons.add)}
              </Button>
            )}
          </Stack>

          {!part?.item?.isGenerating && !part?.item?.isReady && (
            <GenerateAgain
              createPart={part.createPart}
              error={t(tokens.topics.list.noTopicsFound)}
              isGenerating={part?.item?.isGenerating ?? false}
            />
          )}

          {part?.item?.isGenerating && (
            <>
              <Card>
                <CardContent>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </CardContent>
              </Card>
            </>
          )}

          {part?.item?.isReady && (
            <>
              <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleTabsChange}>
                    {tabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                      />
                    ))}
                  </TabList>
                </Box>
                <div
                  style={{
                    display: currentTab === "topics" ? "block" : "none",
                  }}
                >
                  <Card>
                    <TopicListFilters
                      onFiltersChange={(filters) => {
                        setFilters(filters);
                      }}
                    />
                    <Divider />
                    <TopicListTable
                      count={items.totalCount}
                      items={items.items}
                      onPageChange={(event, newPage) => {
                        loadTopics(newPage + 1, items.top);
                      }}
                      onRowsPerPageChange={(input) => {
                        loadTopics(1, Number.parseInt(input.target.value));
                      }}
                      onReset={() => loadTopics(1, items.top)}
                      page={items.page}
                      top={items.top}
                      isLoading={isLoading}
                      hasError={hasLoadingError}
                    />
                  </Card>
                </div>
                <div
                  style={{
                    display: currentTab === "generator" ? "block" : "none",
                  }}
                >
                  <TopicGenerationForm
                    onTopicAdd={async (topic: GeneratedTopic) => {
                      loadTopics(items.page, items.top);
                    }}
                    onTopicRemove={async (topic: GeneratedTopic) => {
                      loadTopics(items.page, items.top);
                    }}
                  />
                </div>
              </TabContext>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Page;
