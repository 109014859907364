export const tokens = {
  landing: {
    title: "landing.title",
    hero: {
      title1: "landing.hero.title1",
      title2: "landing.hero.title2",
      subtitle: "landing.hero.subtitle",
      button: "landing.hero.button",
    },
    nav: {
      strategies: "landing.nav.strategies",
      singIn: "landing.nav.signIn",
      singUp: "landing.nav.signUp",
      privacyPolicy: "landing.nav.privacyPolicy",
      termsOfUse: "landing.nav.termsOfUse",
      panel: "landing.nav.panel",
    },
  },
  nav: {
    host: "nav.host",
    profile: "nav.profile",
    upgradeAccount: "nav.upgradeAccount",
  },
  sidebar: {
    content: "sidebar.content",
    ideas: "sidebar.ideas",
    leadMagnets: "sidebar.leadMagnets",
    contentTemplates: "sidebar.contentTemplates",
    topicGenerator: "sidebar.topicGenerator",
    myBrand: "sidebar.myBrand",
    targetAudiences: "sidebar.targetAudiences",
    aboutMe: "sidebar.aboutMe",
    product: "sidebar.product",
    storylines: "sidebar.storylines",
    recommendations: "sidebar.recommendations",
    instagram: "sidebar.instagram",
    fears: "sidebar.fears",
    general: "sidebar.settings",
    settings: "sidebar.strategy",
    calendar: "sidebar.calendar",
    workspaces: {
      name: "sidebar.workspaces.name",
    },
  },
  wizard: {
    aiHeader: "wizard.aiHeader",
    step: {
      title: "wizard.step.title",
    },
    signup: {
      title: "wizard.signup.title",
      text: "wizard.questions.signup.text",
      changeEmailText: "wizard.questions.signup.changeEmailText",
      changeButtonText: "wizard.questions.signup.changeButtonText",
      tooltip: "wizard.questions.signup.tooltip",
    },
    summary: {
      title: "wizard.questions.summary.title",
      text: "wizard.questions.summary.text",
      tooltip: "wizard.questions.summary.tooltip",
    },
    showExample: "wizard.showExample",
    validators: {
      maxItems: "wizard.validators.maxItems",
    },
    placeholders: {
      name: "wizard.placeholders.name",
      valueProposition: "wizard.placeholders.valueProposition",
      profession: "wizard.placeholders.profession",
      productOrService: {
        title: "wizard.placeholders.productOrService.title",
        description: "wizard.placeholders.productOrService.description",
      },
      targetAudience: {
        title: "wizard.placeholders.targetAudience.title",
        description: "wizard.placeholders.targetAudience.description",
      },
      goals: "wizard.placeholders.goals",
      skills: "wizard.placeholders.skills",
      careerPath: {
        title: "wizard.placeholders.careerPath.title",
        description: "wizard.placeholders.careerPath.description",
      },
    },
  },
  recommendations: {
    instagram: {
      profile: {
        title: "recommendations.instagram.profile.title",
        header: "recommendations.instagram.profile.header",
        description: "recommendations.instagram.profile.description",
      },
      highlights: {
        title: "recommendations.instagram.highlights.title",
        header: "recommendations.instagram.highlights.header",
        description: "recommendations.instagram.highlights.description",
      },
      noDataFound: "recommendations.instagram.profile.noDataFound",
    },
    fears: {
      title: "recommendations.fears.title",
      header: "recommendations.fears.header",
      description: "recommendations.fears.description",
      noDataFound: "recommendations.fears.profile.noDataFound",
    },
  },
  products: {
    list: {
      pageTitle: "products.list.pageTitle",
      pageHeader: "products.list.pageHeader",
      buttons: {
        add: "products.list.buttons.add",
      },
      table: {
        name: "products.list.table.name",
        targetAudiences: "products.list.table.targetAudiences",
        actions: "products.list.table.actions",
      },
      deleteProductDialogTitle: "products.list.deleteProductDialogTitle",
      deleteProductDialogDescription:
        "products.list.deleteProductDialogDescription",
      noProductsFound: "products.list.noProductsFound",
      errorLoadingProducts: "products.list.errorLoadingProducts",
    },
    create: {
      pageTitle: "products.details.pageTitle",
      pageHeader: "products.details.pageHeader",
      form: {
        name: "products.create.form.name",
        description: "products.create.form.description",
        uniqueValueProposition: "products.create.form.uniqueValueProposition",
        successMessage: "products.create.form.successMessage",
      },
      backButton: "products.create.backButton",
    },
    details: {
      pageTitle: "products.details.pageTitle",
      pageHeader: "products.details.pageHeader",
      form: {
        name: "products.details.form.name",
        description: "products.details.form.description",
        landingPage: "products.details.form.landingPage",
        uniqueValueProposition: "products.details.form.uniqueValueProposition",
        targetAudiences: "products.details.form.targetAudiences",
        successMessage: "products.details.form.successMessage",
      },
      backToProductsButton: "products.details.backToProductsButton",
    },
  },
  topics: {
    generator: {
      pageTitle: "topics.generator.pageTitle",
      pageHeader: "topics.generator.pageHeader",
      buttons: {
        addTopic: "topics.generator.buttons.add",
        removeTopic: "topics.generator.buttons.remove",
        generateTopics: "topics.generator.buttons.generate",
      },
      form: {
        storyline: "topics.generator.form.storyline",
        category: "topics.generator.form.category",
        product: "topics.generator.form.product",
        instruction: "topics.generator.form.instruction",
      },
    },
    list: {
      pageTitle: "topics.list.pageTitle",
      pageHeader: "topics.list.pageHeader",
      buttons: {
        add: "topics.list.buttons.add",
      },
      tabs: {
        ideas: "topics.list.tabs.ideas",
        generator: "topics.list.tabs.generator",
      },
      filters: {
        text: "topics.list.filters.text",
        storyline: "topics.list.filters.storyline",
        product: "topics.list.filters.product",
      },
      table: {
        name: "topics.list.table.name",
        product: "topics.list.table.product",
        storyline: "topics.list.table.storyline",
        contentGoalType: "topics.list.table.contentGoalType",
        date: "topics.list.table.status",
        actions: "topics.list.table.actions",
      },
      deleteTopicDialogTitle: "topics.list.deleteTopicDialogTitle",
      deleteTopicDialogDescription: "topics.list.deleteTopicDialogDescription",
      noTopicsFound: "topics.list.noTopicsFound",
      errorLoadingTopics: "topics.list.errorLoadingTopics",
    },
    create: {
      pageTitle: "topics.create.pageTitle",
      pageHeader: "topics.create.pageHeader",
      form: {
        name: "topics.create.form.name",
        description: "topics.create.form.description",
        date: "topics.create.form.date",
        successMessage: "topics.create.form.successMessage",
      },
      backButton: "topics.create.backButton",
    },
    details: {
      pageTitle: "topics.details.pageTitle",
      pageHeader: "topics.details.pageHeader",
      form: {
        name: "topics.details.form.name",
        description: "topics.details.form.description",
        content: "topics.details.form.content",
        generationDialogHeader: "topics.details.form.generateDialogHeader",
        template: "topics.details.form.template",
        instruction: "topics.details.form.instruction",
        titleRequired: "topics.details.form.titleRequired",
        date: "topics.details.form.date",
        generateButton: "topics.details.form.generateButton",
        updateSuccessMessage: "topics.details.form.updateSuccessMessage",
        createSuccessMessage: "topics.details.form.createSuccessMessage",
      },
      backButton: "topics.details.backButton",
    },
  },
  leadMagnets: {
    generator: {
      pageTitle: "leadMagnets.generator.pageTitle",
      pageHeader: "leadMagnets.generator.pageHeader",
      buttons: {
        addLeadMagnet: "leadMagnets.generator.buttons.add",
        removeLeadMagnet: "leadMagnets.generator.buttons.remove",
        generateLeadMagnets: "leadMagnets.generator.buttons.generate",
      },
      form: {
        product: "leadMagnets.generator.form.product",
        instruction: "leadMagnets.generator.form.instruction",
      },
    },
    list: {
      pageTitle: "leadMagnets.list.pageTitle",
      pageHeader: "leadMagnets.list.pageHeader",
      buttons: {
        add: "leadMagnets.list.buttons.add",
      },
      tabs: {
        leadMagnets: "leadMagnets.list.tabs.leadMagnets",
        generator: "leadMagnets.list.tabs.generator",
      },
      filters: {
        text: "leadMagnets.list.filters.text",
        product: "leadMagnets.list.filters.product",
      },
      table: {
        name: "leadMagnets.list.table.name",
        product: "leadMagnets.list.table.product",
        type: "leadMagnets.list.table.type",
        complexity: "leadMagnets.list.table.complexity",
        actions: "leadMagnets.list.table.actions",
      },
      deleteLeadMagnetDialogTitle:
        "leadMagnets.list.deleteLeadMagnetDialogTitle",
      deleteLeadMagnetDialogDescription:
        "leadMagnets.list.deleteLeadMagnetDialogDescription",
      noLeadMagnetsFound: "leadMagnets.list.noLeadMagnetsFound",
      errorLoadingLeadMagnets: "leadMagnets.list.errorLoadingLeadMagnets",
    },
    create: {
      pageTitle: "leadMagnets.details.pageTitle",
      pageHeader: "leadMagnets.details.pageHeader",
      form: {
        name: "leadMagnets.create.form.name",
        description: "leadMagnets.create.form.description",
        successMessage: "leadMagnets.create.form.successMessage",
      },
      backButton: "leadMagnets.create.backButton",
    },
    details: {
      pageTitle: "leadMagnets.details.pageTitle",
      pageHeader: "leadMagnets.details.pageHeader",
      form: {
        name: "leadMagnets.details.form.name",
        description: "leadMagnets.details.form.description",
        successMessage: "leadMagnets.details.form.successMessage",
      },
      backButton: "leadMagnets.details.backButton",
    },
  },
  storylines: {
    list: {
      pageTitle: "storylines.list.pageTitle",
      pageHeader: "storylines.list.pageHeader",
      buttons: {
        add: "storylines.list.buttons.add",
      },
      table: {
        name: "storylines.list.table.name",
        contentGoal: "storylines.list.table.type",
        actions: "storylines.list.table.actions",
      },
      deleteStorylineDialogTitle: "storylines.list.deleteStorylineDialogTitle",
      deleteStorylineDialogDescription:
        "storylines.list.deleteStorylineDialogDescription",
      noStorylinesFound: "storylines.list.noStorylinesFound",
      errorLoadingStorylines: "storylines.list.errorLoadingStorylines",
    },
    create: {
      pageTitle: "storylines.details.pageTitle",
      pageHeader: "storylines.details.pageHeader",
      form: {
        name: "storylines.create.form.name",
        contentGoal: "storylines.create.form.contentGoal",
        description: "storylines.create.form.description",
        categories: "storylines.create.form.categories",
        placeholders: {
          name: "storylines.create.form.placeholders.name",
          description: "storylines.create.form.placeholders.description",
        },
        noCategories: "storylines.create.form.noCategories",
        successMessage: "storylines.create.form.successMessage",
      },
      backButton: "storylines.create.backButton",
    },
    details: {
      pageTitle: "storylines.details.pageTitle",
      pageHeader: "storylines.details.pageHeader",
      form: {
        name: "storylines.details.form.name",
        contentGoalType: "storylines.details.form.contentGoalType",
        description: "storylines.details.form.description",
        categories: "storylines.details.form.categories",
        placeholders: {
          name: "storylines.details.form.placeholders.name",
          description: "storylines.details.form.placeholders.description",
        },
        noCategories: "storylines.details.form.noCategories",
        noDescription: "storylines.details.form.noDescription",
        successMessage: "storylines.details.form.successMessage",
      },
      backButton: "storylines.details.backButton",
    },
  },
  storylinesCategories: {
    details: {
      pageTitle: "storylinesCategories.details.pageTitle",
      form: {
        topics: "storylinesCategories.details.form.topics",
        noTopics: "storylinesCategories.details.form.noTopics",
        addTopic: "storylinesCategories.details.form.addTopic",
        generateTopics: "storylinesCategories.details.form.generateTopics",
        topicUpdatedMessage:
          "storylinesCategories.details.form.topicUpdatedMessage",
        topicCreatedMessage:
          "storylinesCategories.details.form.topicCreatedMessage",
        categoryUpdateMessage:
          "storylinesCategories.details.form.categoryUpdateMessage",
      },
      backButton: "storylinesCategories.details.backButton",
      deleteTopicDialogTitle:
        "storylinesCategories.details.deleteTopicDialogTitle",
      deleteTopicDialogDescription:
        "storylinesCategories.details.deleteTopicDialogDescription",
      deleteCategoryDialogTitle:
        "storylinesCategories.details.deleteCategoryDialogTitle",
      deleteCategoryDialogDescription:
        "storylinesCategories.details.deleteCategoryDialogDescription",
    },
  },
  contentPlaceholders: {
    pageHeader: "contentPlaceholders.pageHeader",
    form: {
      date: "contentPlaceholders.form.date",
      contentGoal: "contentPlaceholders.form.contentGoal",
      channel: "contentPlaceholders.form.channel",
      name: "contentPlaceholders.form.name",
      description: "contentPlaceholders.form.description",
      content: "contentPlaceholders.form.content",
    },
    options: {
      newTopic: "contentPlaceholders.options.newTopic",
      selectTopic: "contentPlaceholders.options.selectTopic",
      generateTopic: "contentPlaceholders.options.generateTopic",
    },
    deleteContentPlaceholderDialogTitle:
      "contentPlaceholders.deleteContentPlaceholderDialogTitle",
    deleteContentPlaceholderDialogDescription:
      "contentPlaceholders.deleteContentPlaceholderDialogDescription",
  },
  calendar: {
    contentPlanSchedule: {
      pageHeader: "calendar.contentPlanSchedule.pageHeader",
    },
    generateTopic: {
      pageHeader: "calendar.generateTopic.pageHeader",
    },
    selectTopic: {
      pageHeader: "calendar.selectTopic.pageHeader",
      errorLoadingTopics: "calendar.selectTopic.errorLoadingTopics",
      noTopicsFound: "calendar.selectTopic.noTopicsFound",
    },
  },
  contentTemplates: {
    list: {
      pageTitle: "contentTemplates.list.pageTitle",
      pageHeader: "contentTemplates.list.pageHeader",
      buttons: {
        add: "contentTemplates.list.buttons.add",
      },
      table: {
        name: "contentTemplates.list.table.name",
        actions: "contentTemplates.list.table.actions",
      },
      deleteContentTemplateDialogTitle:
        "contentTemplates.list.deleteContentTemplateDialogTitle",
      deleteContentTemplateDialogDescription:
        "contentTemplates.list.deleteContentTemplateDialogDescription",
      noContentTemplatesFound: "contentTemplates.list.noContentTemplatesFound",
      errorLoadingContentTemplates:
        "contentTemplates.list.errorLoadingContentTemplates",
    },
    create: {
      pageTitle: "contentTemplates.details.pageTitle",
      pageHeader: "contentTemplates.details.pageHeader",
      form: {
        name: "contentTemplates.create.form.name",
        instruction: "contentTemplates.create.form.instruction",
        examples: "contentTemplates.create.form.examples",
        successMessage: "contentTemplates.create.form.successMessage",
        noExamples: "contentTemplates.create.form.noExamples",
      },
      backButton: "contentTemplates.create.backButton",
    },
    details: {
      pageTitle: "contentTemplates.details.pageTitle",
      pageHeader: "contentTemplates.details.pageHeader",
      form: {
        name: "contentTemplates.details.form.name",
        instruction: "contentTemplates.details.form.instruction",
        examples: "contentTemplates.details.form.examples",
        successMessage: "contentTemplates.details.form.successMessage",
        noExamples: "contentTemplates.details.form.noExamples",
      },
      backButton: "contentTemplates.details.backButton",
    },
  },
  targetAudiences: {
    list: {
      pageTitle: "targetAudiences.list.pageTitle",
      pageHeader: "targetAudiences.list.pageHeader",
      buttons: {
        add: "targetAudiences.list.buttons.add",
      },
      table: {
        name: "targetAudiences.list.table.name",
        products: "targetAudiences.list.table.products",
        actions: "targetAudiences.list.table.actions",
      },
      deleteTargetAudienceDialogTitle:
        "targetAudiences.list.deleteTargetAudienceDialogTitle",
      deleteTargetAudienceDialogDescription:
        "targetAudiences.list.deleteTargetAudienceDialogDescription",
      noTargetAudiencesFound: "targetAudiences.list.noTargetAudiencesFound",
      errorLoadingTargetAudiences:
        "targetAudiences.list.errorLoadingTargetAudiences",
    },
    create: {
      pageTitle: "targetAudiences.create.pageTitle",
      pageHeader: "targetAudiences.create.pageHeader",
      form: {
        name: "targetAudiences.create.form.name",
        description: "targetAudiences.create.form.description",
        content: "targetAudiences.create.form.content",
        products: "targetAudiences.create.form.products",
        successMessage: "targetAudiences.create.form.successMessage",
      },
      backButton: "targetAudiences.create.backButton",
    },
    details: {
      sections: {
        goals: "targetAudiences.details.sections.goals",
        pains: "targetAudiences.details.sections.pains",
      },
    },
    edit: {
      pageTitle: "targetAudiences.edit.pageTitle",
      pageHeader: "targetAudiences.edit.pageHeader",
      form: {
        name: "targetAudiences.edit.form.name",
        description: "targetAudiences.edit.form.description",
        idealCustomer: "targetAudiences.edit.form.idealCustomer",
        goals: "targetAudiences.edit.form.goals",
        pains: "targetAudiences.edit.form.pains",
        products: "targetAudiences.edit.form.products",
        successMessage: "targetAudiences.edit.form.successMessage",
      },
      backToTargetAudiencessButton:
        "targetAudiences.edit.backToTargetAudiencessButton",
    },
  },

  aboutMe: {
    title: "aboutMe.title",
    header: "aboutMe.header",
    noAboutMeFound: "aboutMe.noAboutMeFound",
    sections: {
      aboutMe: "aboutMe.sections.aboutMe",
      goals: "aboutMe.sections.goals",
      mission: "aboutMe.sections.mission",
      goalsPriority: "aboutMe.sections.goalsPriority",
      skills: "aboutMe.sections.skills",
      productsOrServices: "aboutMe.sections.productsOrServices",
      productOrService: "aboutMe.sections.productOrService",
      positionings: "aboutMe.sections.positionings",
      voice: "aboutMe.sections.voice",
      expertContent: "aboutMe.sections.expertContent",
      personalContent: "aboutMe.sections.personalContent",
      contentVision: "aboutMe.sections.contentVision",
      channels: "aboutMe.sections.channels",
      strengths: "aboutMe.sections.strengths",
      values: "aboutMe.sections.values",
      slogan: "aboutMe.sections.slogan",
    },
  },
  strategySettings: {
    title: "strategySettings.title",
    header: "strategySettings.header",
    noStrategySettingsFound: "strategySettings.noStrategySettingsFound",
    errorLoading: "strategySettings.errorLoading",
    form: {
      name: "strategySettings.form.name",
      successMessage: "strategySettings.form.successMessage",
    },
    tabs: {
      general: "strategySettings.tabs.general",
      channels: "strategySettings.tabs.channels",
    },
    export: {
      header: "strategySettings.export.header",
      explanation: "strategySettings.export.explanation",
    },
    detele: {
      header: "strategySettings.delete.header",
      explanation: "strategySettings.delete.explanation",
      deleteStrategyDialogTitle:
        "strategySettings.list.deleteStrategyDialogTitle",
      deleteStrategyDialogDescription:
        "strategySettings.list.deleteStrategyDialogDescription",
    },
    plan: {
      header: "strategySettings.plan.header",
      status: "strategySettings.plan.status",
      active: "strategySettings.plan.active",
      currentPlan: "strategySettings.plan.currentPlan",
    },
    buttons: {
      export: "strategySettings.buttons.export",
      delete: "strategySettings.buttons.delete",
      upgrade: "strategySettings.buttons.upgrade",
    },
  },
  channels: {
    errorLoadingChannels: "channels.errorLoadingChannels",
    form: {
      successMessage: "channels.form.successMessage",
      noChannels: "channels.form.noChannels",
    },
  },
  export: {
    sections: {
      instagram: "export.sections.instagram",
      fears: "export.sections.fears",
      products: "export.sections.products",
      targetAudiences: "export.sections.targetAudiences",
      storylines: "export.sections.storylines",
    },
  },
  fears: {
    title: "fears.title",
    header: "fears.header",
    noFearsFound: "fears.noFearsFound",
    errorLoading: "fears.errorLoading",
  },
  payment: {
    statuses: {
      approved: "payment.statuses.approved",
      pending: "payment.statuses.pending",
      unknown: "payment.statuses.unknown",
      declined: "payment.statuses.declined",
      refunded: "payment.statuses.refunded",
      noPayment: "payment.statuses.noPayment",
    },
    success: {
      title: "account.payment.success.title",
      header: "account.payment.success.header",
      product: "account.payment.success.product",
      email: "account.payment.success.email",
      orderReference: "account.payment.success.orderReference",
      status: "account.payment.success.status",
      openDashboardButton: "account.payment.success.openDashboardButton",
      messages: {
        approved: "account.payment.success.messages.approved",
        pending: "account.payment.success.messages.pending",
        declined: "account.payment.success.messages.declined",
        unknown: "account.payment.success.messages.unknown",
      },
    },
    error: {
      title: "account.payment.error.title",
      header: "account.payment.error.header",
      message: "account.payment.error.message",
      orderReference: "account.payment.error.orderReference",
      unknownStatus: "account.payment.error.unknownStatus",
    },
  },
  account: {
    signUp: {
      title: "account.signUp.title",
      header: "account.signUp.header",
      form: {
        firstName: "account.signUp.form.firstName",
        lastName: "account.signUp.form.lastName",
        phone: "account.signUp.form.phone",
        email: "account.signUp.form.email",
        password: "account.signUp.form.password",
        createAccountButton: "account.signUp.form.createAccountButton",
        termsOfUseLink: "account.signUp.form.termsOfUseLink",
        privacyPolicyLink: "account.signUp.form.privacyPolicyLink",
        andSeparator: "account.signUp.form.andSeparator",
        agreementText: "account.signUp.form.agreementText",
        signinLink: "account.signUp.form.signinLink",
        hasAccount: "account.signUp.form.hasAccount",
      },
    },
    signIn: {
      title: "account.signIn.title",
      header: "account.signIn.header",
      form: {
        email: "account.signIn.form.email",
        signInButton: "account.signIn.form.signInButton",
        termsOfUseLink: "account.signUp.form.termsOfUseLink",
        privacyPolicyLink: "account.signUp.form.privacyPolicyLink",
        andSeparator: "account.signUp.form.andSeparator",
        agreementText: "account.signUp.form.agreementText",
      },
    },
    resetPassword: {
      title: "account.resetPassword.title",
      header: "account.resetPassword.header",
      signInLink: "account.resetPassword.signInLink",
      form: {
        passwordChangedMessage:
          "account.resetPassword.form.passwordChangedMessage",
        password: "account.resetPassword.form.password",
        confirmPassword: "account.resetPassword.form.confirmPassword",
        resetButton: "account.resetPassword.form.resetButton",
        linkInvalidError: "account.resetPassword.form.linkInvalidError",
        linkInvalidOrExpiredError:
          "account.resetPassword.form.linkInvalidOrExpiredError",
      },
    },
    confirmSignIn: {
      title: "account.confirmSignIn.title",
      expiredToken: "account.confirmSignIn.expiredToken",
      strategyIsFinished: "account.confirmSignIn.strategyIsFinished",
    },
    emailSent: {
      title: "account.emailSent.title",
      header: "account.emailSent.header",
      message: "account.emailSent.message",
    },
    forgotPassword: {
      title: "account.resetPassword.title",
      header: "account.resetPassword.header",
      signInLink: "account.resetPassword.signInLink",
      form: {
        emailSentMessage: "account.resetPassword.form.emailSentMessage",
        email: "account.resetPassword.form.email",
        resetButton: "account.resetPassword.form.resetButton",
      },
    },
    popover: {
      account: "account.popover.settings",
      impersonate: "account.popover.impersonate",
      stopImpersonation: "account.popover.stopImpersonation",
      signOut: "account.popover.signOut",
    },
    colorScheme: {
      light: "account.colorScheme.light",
      dark: "account.colorScheme.dark",
      scheme: "account.colorScheme.scheme",
    },
    profile: {
      title: "account.profile.title",
      header: "account.profile.header",
      passwordHeader: "account.profile.passwordHeader",
      accountDetailsHeader: "account.profile.accountDetailsHeader",
      accountProfileImage: "account.profile.accountProfileImage",
      tab: {
        general: "account.profile.tab.general",
      },
      form: {
        firstName: "account.profile.form.firstName",
        lastName: "account.profile.form.lastName",
        email: "account.profile.form.email",
        currentPassword: "account.profile.form.currentPassword",
        newPassword: "account.profile.form.newPassword",
        confirmPassword: "account.profile.form.confirmPassword",
        passwordUpdatedMessage: "account.profile.form.passwordUpdatedMessage",
        accountUpdatedMessage: "account.profile.form.accountUpdatedMessage",
      },
    },
    impersonate: {
      form: {
        header: "account.impersonate.header",
        searchForUser: "account.impersonate.form.searchForUser",
        buttons: {
          impersonate: "account.impersonate.form.buttons.impersonate",
        },
      },
    },
  },
  contact: {
    title: "contact.title",
    header: "contact.header",
    subHeader: "contact.subHeader",
    form: {
      firstName: "contact.form.firstName",
      lastName: "contact.form.lastName",
      phone: "contact.form.phone",
      email: "contact.form.email",
      message: "contact.form.message",
      sendButton: "contact.form.createAccountButton",
      termsOfUseLink: "contact.form.termsOfUseLink",
      privacyPolicyLink: "contact.form.privacyPolicyLink",
      andSeparator: "contact.form.andSeparator",
      agreementText: "contact.form.agreementText",
      successTitle: "contact.form.successTitle",
      successMessage1: "contact.form.successMessage1",
      successMessage2: "contact.form.successMessage2",
    },
  },
  profile: {
    title: "profile.title",
  },

  errors: {
    serverError: {
      title: "errors.serverError.title",
      header: "errors.serverError.header",
      message: "errors.serverError.message",
    },
    notFound: {
      title: "errors.notFound.title",
      header: "errors.notFound.header",
      message: "errors.notFound.message",
    },
    notAuthorized: {
      title: "errors.notAuthorized.title",
      header: "errors.notAuthorized.header",
      message: "errors.notAuthorized.message",
    },
  },
  general: {
    languageChanged: "general.languageChanged",
    language: "general.language",
    newAppVersion: {
      description: "general.newAppVersion.description",
      action: "general.newAppVersion.action",
    },
    placeholders: {
      editor: "general.placeholders.editor",
    },
    filters: {
      title: "general.filters.title",
      emptyOptions: "general.filters.emptyOptions",
    },
    formError: "formError",
    loading: "Loading",
    paginationRowsPerPage: "general.paginationRowsPerPage",
    validators: {
      password: {
        minLength: "general.validators.password.minLength",
      },
      passwordConfirm: {
        notMatch: "general.validators.passwordConfirm.notMatch",
      },
      policy: {
        required: "general.validators.policy.required",
      },
      user: {
        conflict: "general.validators.user.conflict",
      },
      employee: {
        conflict: "general.validators.employee.conflict",
        accountConnected: "general.validators.employee.accountConnected",
      },
      role: {
        required: "general.validators.role.required",
        min: "general.validators.role.min",
      },
      invalidEmail: "general.validators.invalidEmail",
      roleRequired: "general.validators.roleRequired",
      required: "general.validators.required",
      maxLength50: "general.validators.maxLength50",
      maxLength255: "general.validators.maxLength255",
      maxLength1000: "general.validators.maxLength1000",
      oneItemRequired: "general.validators.oneItemRequired",
    },
    filepicker: {
      titleHighlight: "general.filepicker.titleHighlight",
      titleNormal: "general.filepicker.titleNormal",
      imageExtensions: "general.filepicker.imageExtensions",
      excelExtensions: "general.filepicker.excelExtensions",
      fileSizeError: "general.filepicker.fileSizeError",
      deleteImage: "general.filepicker.deleteImage",
      imageUploaded: "general.filepicker.imageUploaded",
    },
    actions: {
      edit: "general.actions.edit",
      delete: "general.actions.delete",
    },
    aiGeneration: {
      pageHeader: "general.aiGeneration.pageHeader",
    },
    plans: {
      basic: "general.plans.basic",
      professional: "general.plans.professional",
      free: "general.plans.unknown",
    },
    contentGoalTypes: {
      notSelected: "general.contentGoalTypes.notSelected",
      engagement: "general.contentGoalTypes.engagement",
      community: "general.contentGoalTypes.community",
      selling: "general.contentGoalTypes.selling",
    },
    complexity: {
      unknown: "general.complexity.unknown",
      easy: "general.complexity.easy",
      medium: "general.complexity.medium",
      complicated: "general.complexity.complicated",
    },
    buttons: {
      ok: "general.buttons.ok",
      delete: "general.buttons.delete",
      edit: "general.buttons.edit",
      add: "general.buttons.add",
      copy: "general.buttons.copy",
      invite: "general.buttons.invite",
      update: "general.buttons.update",
      archive: "general.buttons.archive",
      save: "general.buttons.save",
      create: "general.buttons.create",
      generate: "general.buttons.generate",
      cancel: "general.buttons.cancel",
      close: "general.buttons.close",
      loadMore: "general.buttons.loadMore",
      more: "general.buttons.more",
      tryAgain: "general.buttons.tryAgain",
      backToHome: "general.buttons.backToHome",
      goBack: "general.buttons.goBack",
      addItem: "general.buttons.addItem",
      next: "general.buttons.next",
      back: "general.buttons.back",
      generateAgain: "general.buttons.generateAgain",
      generateStrategy: "general.buttons.generateStrategy",
      finish: "general.buttons.createAccount",
    },
  },
};
