export enum StrategyPartType {
  None,
  TargetAudiences,
  Fears,
  Storylines,
  Product,
  StrategyName,
  InstagramProfile,
  Topics,
  AboutMe,
  LeadMagnets,
  ContentTemplates,
}

export enum SectionFormType {
  None,
  ShortText,
  LongText,
  TwoFields,
  List,
}

export enum PlanType {
  Free,

  Strategy,

  ContentPlan,
}

export interface Strategy {
  id: string;
  name: string;
  aboutMe: string;
  area: string;
  reasons: string;
  skills: string;
  goals: string;
  slogan: string;
  values: string;
  mission: string;
  fears: string;
  instagramTitle: string;
  instagramDescription: string;
  strengths: string;
  contentVision: string;
  expertContent: string;
  personalContent: string;
  isComplete: boolean;
  planType: PlanType;
  parts: StrategyPart[];
  product?: Product;
}

export interface StrategyPart {
  isGenerating: boolean;
  isReady: boolean;
  hasError: boolean;
  partType: StrategyPartType;
}

export interface StrategyStepConfiguration {
  title: string;
  description: string;
  example?: string;
  hasInstruction: boolean;
  sectionKey?: string;
  options?: any;
  formType?: SectionFormType;
}

export interface StrategyStep {
  configuration: StrategyStepConfiguration;
  value: any;
  //hasInstruction: boolean;
  instructionResult?: InstructionResult;
}

export interface InstructionResult {
  options: any[] | undefined;
  isOptionsLoading: boolean;
  isOptionsError: boolean;
}

export interface Product {
  id: string;
  name: string;
  description: string;
}
