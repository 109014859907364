import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { Layout as MarketingLayout } from "src/layouts/marketing/layout";
import { Layout as LandingLayout } from "src/layouts/landing/layout";
// import { Layout as AuthorizedLayout } from "src/layouts/authorized/layout";
import { Layout as WizardLayout } from "src/layouts/wizard";
import { Layout as EmptyLayout } from "src/layouts/empty";
import { Layout as DashboardLayout } from "src/layouts/dashboard/layout";
import HomePage from "src/pages";
import WizardStepPage from "src/pages/wizard/step";
import WizardFinishPage from "src/pages/wizard/finish";
import WizardSignupPage from "src/pages/wizard/signup";
import DomainPage from "src/pages/domain";
import StrategyExportPage from "src/pages/dashboard/export";
import ProductDetailsPage from "src/pages/dashboard/products/details";
import ProductEditPage from "src/pages/dashboard/products/edit";
import TopicsListPage from "src/pages/dashboard/topics/list";
import TopicDetailsPage from "src/pages/dashboard/topics/details";
import TopicCreatePage from "src/pages/dashboard/topics/create";
import LeadMagnetsListPage from "src/pages/dashboard/lead-magnets/list";
import LeadMagnetsDetailsPage from "src/pages/dashboard/lead-magnets/details";
import LeadMagnetsCreatePage from "src/pages/dashboard/lead-magnets/create";
import ContentTemplatesListPage from "src/pages/dashboard/content-templates/list";
import ContentTemplatesDetailsPage from "src/pages/dashboard/content-templates/details";
import ContentTemplatesCreatePage from "src/pages/dashboard/content-templates/create";
import ContentTemplatesEditPage from "src/pages/dashboard/content-templates/edit";
import StorylinesListPage from "src/pages/dashboard/storylines/list";
import StorylineDetailsPage from "src/pages/dashboard/storylines/details";
import StorylineCreatePage from "src/pages/dashboard/storylines/create";
import StorylineEditPage from "src/pages/dashboard/storylines/edit";
import StorylineCategoryDetailsPage from "src/pages/dashboard/storyline-categories/details";
import TargetAudiencesListPage from "src/pages/dashboard/target-audiences/list";
import TargetAudienceEditPage from "src/pages/dashboard/target-audiences/edit";
import TargetAudienceDetailsPage from "src/pages/dashboard/target-audiences/details";
import TargetAudienceCreatePage from "src/pages/dashboard/target-audiences/create";
import ProfilePage from "src/pages/dashboard/profile";
import SettingsPage from "src/pages/dashboard/strategy-settings";
import PaymentPage from "src/pages/payment";
import PaymentSuccessPage from "src/pages/payment/success";
import PaymentErrorPage from "src/pages/payment/error";
import FearsPage from "src/pages/dashboard/fears";
import PricingPage from "src/pages/pricing";
import InstagramProfile from "src/pages/dashboard/instagram-profile";
import AccountPage from "src/pages/dashboard/account";
import CalendarPage from "src/pages/dashboard/calendar";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
// import ContactPage from 'src/pages/contact';
// import PricingPage from 'src/pages/pricing';
import PrivacyPolicyPage from "src/pages/privacy-policy";
import TermsOfUsePage from "src/pages/terms-of-use";
import { authRoutes } from "./auth";

export const routes: RouteObject[] = [
  {
    element: (
      <LandingLayout>
        <Outlet />
      </LandingLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />,
      },
    ],
  },
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      // {
      //   path: 'pricing',
      //   element: <PricingPage />
      // },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "terms-of-use",
        element: <TermsOfUsePage />,
      },
      {
        path: "payment/success",
        element: <PaymentSuccessPage />,
      },
      {
        path: "payment/error",
        element: <PaymentErrorPage />,
      },
    ],
  },
  {
    element: (
      <WizardLayout>
        <Outlet />
      </WizardLayout>
    ),
    children: [
      {
        path: "/wizard/:strategyId/finish",
        element: <WizardFinishPage />,
      },
      {
        path: "/wizard/:strategyId/signup",
        element: <WizardSignupPage />,
      },
      {
        path: "/wizard/:strategyId/step/:step",
        element: <WizardStepPage />,
      },
    ],
  },
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        path: "/domain",
        element: <DomainPage />,
      },
    ],
  },
  {
    element: (
      <EmptyLayout>
        <Outlet />
      </EmptyLayout>
    ),
    children: [
      {
        path: "/strategies/:strategyId/export",
        element: <StrategyExportPage />,
      },
    ],
  },
  {
    element: (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ),
    children: [
      {
        path: "/pricing",
        element: <PricingPage />,
      },
      {
        path: "/payment",
        element: <PaymentPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/settings",
        element: <SettingsPage />,
      },
      {
        path: "/fears",
        element: <FearsPage />,
      },
      {
        path: "/instagram-profile",
        element: <InstagramProfile />,
      },
      {
        path: "/account/settings",
        element: <AccountPage />,
      },
    ],
  },
  {
    element: (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ),
    children: [
      {
        path: "/product",
        element: <ProductDetailsPage />,
      },
      {
        path: "/products/:productId/edit",
        element: <ProductEditPage />,
      },
      {
        path: "/topics",
        element: <TopicsListPage />,
      },
      {
        path: "/topics/create",
        element: <TopicCreatePage />,
      },
      {
        path: "/topics/:topicId",
        element: <TopicDetailsPage />,
      },
      {
        path: "/lead-magnets",
        element: <LeadMagnetsListPage />,
      },
      {
        path: "/lead-magnets/create",
        element: <LeadMagnetsCreatePage />,
      },
      {
        path: "/lead-magnets/:leadMagnetId",
        element: <LeadMagnetsDetailsPage />,
      },
      {
        path: "/storylines",
        element: <StorylinesListPage />,
      },
      {
        path: "/storylines/create",
        element: <StorylineCreatePage />,
      },
      {
        path: "/storylines/:storylineId",
        element: <StorylineDetailsPage />,
      },
      {
        path: "/storylines/:storylineId/categories/:categoryId",
        element: <StorylineCategoryDetailsPage />,
      },
      {
        path: "/storylines/:storylineId/edit",
        element: <StorylineEditPage />,
      },
      {
        path: "/target-audiences",
        element: <TargetAudiencesListPage />,
      },
      {
        path: "/target-audiences/create",
        element: <TargetAudienceCreatePage />,
      },
      {
        path: "/target-audiences/:targetAudienceId",
        element: <TargetAudienceDetailsPage />,
      },
      {
        path: "/target-audiences/:targetAudienceId/edit",
        element: <TargetAudienceEditPage />,
      },
      {
        path: "/content-templates",
        element: <ContentTemplatesListPage />,
      },
      {
        path: "/content-templates/create",
        element: <ContentTemplatesCreatePage />,
      },
      {
        path: "/content-templates/:contentTemplateId",
        element: <ContentTemplatesDetailsPage />,
      },
      {
        path: "/content-templates/:contentTemplateId/edit",
        element: <ContentTemplatesEditPage />,
      },
      {
        path: "/calendar",
        element: <CalendarPage />,
      },
    ],
  },

  ...authRoutes,
  // {
  //   path: 'contact',
  //   element: <ContactPage />
  // },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
