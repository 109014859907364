import { useCallback, type FC } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { Storyline, StorylineCategory } from "src/types/storyline";
import { ContentGoalType } from "src/types/content-goal-type";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import { useRouter } from "src/hooks/use-router";
import { createRoute } from "react-router-url-params";
import { paths } from "src/paths";
import { ItemMenu } from "src/sections/common/item-menu";

interface StorylineDetailsProps {
  storyline: Storyline;
}

export const StorylineDetails: FC<StorylineDetailsProps> = (props) => {
  const { storyline } = props;
  const { t } = useTranslation();

  const getContentGoalType = useCallback(
    (contentGoalType: ContentGoalType) => {
      switch (contentGoalType) {
        case ContentGoalType.Engagement:
          return t(tokens.general.contentGoalTypes.engagement);
        case ContentGoalType.Community:
          return t(tokens.general.contentGoalTypes.community);
        case ContentGoalType.Selling:
          return t(tokens.general.contentGoalTypes.selling);
        default:
          return "";
      }
    },
    [t]
  );

  return (
    <Stack spacing={4}>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h4">{storyline.name}</Typography>
          </Stack>
          <Stack
            sx={{ mb: 2 }}
            direction="row"
            spacing={1}
            alignContent={"center"}
          >
            <Chip
              variant="outlined"
              color="primary"
              label={getContentGoalType(storyline.contentGoalType)}
            />
          </Stack>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: storyline.description.replaceAll("\n", "<br/>"),
            }}
          ></Typography>
        </CardContent>
      </Card>

      <Card sx={{ display: "flex", flexDirection: "column" }}>
        <CardHeader title={t(tokens.storylines.details.form.categories)} />
        <Divider />
        {storyline.categories.length === 0 && (
          <CardContent>
            <Typography variant="body2">
              {t(tokens.storylines.details.form.noCategories)}
            </Typography>
          </CardContent>
        )}
        <Box sx={{ overflowX: "auto" }}>
          <List disablePadding>
            {storyline.categories.map((item, index) => (
              <StorylineCategoryItem
                storylineId={storyline.id}
                category={item}
                showDivider={index < storyline.categories.length - 1}
              />
            ))}
          </List>
        </Box>
      </Card>
    </Stack>
  );
};

interface StorylineCategoryItemProps {
  storylineId: string;
  category: StorylineCategory;
  showDivider: boolean;
}

export const StorylineCategoryItem: FC<StorylineCategoryItemProps> = (
  props
) => {
  const { category, showDivider, storylineId } = props;
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <>
      <ListItemButton
        divider={showDivider}
        sx={{ p: 2 }}
        onClick={() => {
          var url = createRoute(paths.storylineCategories.details).link({
            storylineId: storylineId,
            categoryId: category.id,
          });
          router.push(url);
        }}
      >
        <ListItemText
          disableTypography
          primary={<Typography variant="body1">{category.name}</Typography>}
          secondary={
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {category.description
                ? category.description
                : t(tokens.storylines.details.form.noDescription)}
            </Typography>
          }
        />

        <Avatar>{category.topicsCount}</Avatar>
        {/* <ItemMenu
          onDelete={() => {
            //setOpenDeleteDialog(true);
          }}
        /> */}
        {/* <IconButton
          color="inherit"

          onClick={(e) => {
            e.stopPropagation();
            var url = createRoute(paths.storylineCategories.details).link({
              storylineId: storylineId,
              categoryId: category.id,
            });
            router.push(url);
          }}
        >
          <SvgIcon>
            <ArrowForwardIcon />
          </SvgIcon>
        </IconButton> */}
      </ListItemButton>
    </>
  );
};
