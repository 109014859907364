import { useCallback, useEffect } from "react";
// import { subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { Box, Button, Stack, SvgIcon } from "@mui/material";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import type { Page as PageType } from "src/types/page";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "src/store";
import { thunks as storylineCategoryThunks } from "src/thunks/storyline-category";
import { useParams } from "react-router";
import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import NotFound from "src/sections/errors/not-found";
import { ERRORS } from "src/consts";
import ServerError from "src/sections/errors/server-error";
import { createRoute } from "react-router-url-params";
import EditIcon from "@mui/icons-material/Edit";
import { StorylineCategoryDetails } from "src/sections/dashboard/storyline-categories/storyline-categories-details";
import { useRouter } from "src/hooks/use-router";

const Page: PageType = () => {
  const { t } = useTranslation();

  usePageView();

  const dispatch = useDispatch();
  const { categoryId, storylineId } = useParams();

  const {
    item: storylineCategory,
    error: storylineError,
    //isLoading: isStorylineLoading,
  } = useSelector((state) => state.storylineCategory);
  const { strategyId } = useSelector((state) => state.settings);
  const router = useRouter();

  const loadData = useCallback(async () => {
    if (categoryId) {
      await dispatch(
        storylineCategoryThunks.getStorylineCategory(
          strategyId!,
          storylineId!,
          categoryId
        )
      );
    }
  }, [dispatch, categoryId, storylineId, strategyId]);

  useEffect(() => {
    loadData();

    return () => {
      dispatch(storylineCategoryThunks.clearStorylineCategory());
    };
  }, [categoryId, loadData, dispatch]);

  if (storylineError) {
    if (storylineError.status === ERRORS.NOT_FOUND_ERROR.status) {
      return <NotFound showBackButton={false} />;
    }
    return <ServerError showBackButton={false} />;
  }

  if (!categoryId || !categoryId || !storylineCategory) {
    return;
  }

  return (
    <>
      <Seo title={storylineCategory.name} />
      <Box
        sx={{
          "--Content-maxWidth": "var(--maxWidth-lg)",
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
          >
            <Button
              component={RouterLink}
              href={createRoute(paths.storylines.details).link({
                storylineId: storylineCategory.storyline.id,
              })}
              startIcon={
                <SvgIcon>
                  <ArrowLeftIcon />
                </SvgIcon>
              }
              size="small"
              color="inherit"
              variant="text"
            >
              {storylineCategory.storyline.name}
            </Button>

            {/* <Button
              component={RouterLink}
              href={createRoute(paths.storylines.edit).link({
                storylineId: storylineCategory.id,
              })}
              startIcon={
                <SvgIcon>
                  <EditIcon />
                </SvgIcon>
              }
              size="small"
              color="inherit"
              variant="outlined"
            >
              {t(tokens.general.buttons.edit)}
            </Button> */}
          </Stack>

          <StorylineCategoryDetails
            category={storylineCategory}
            onDelete={() => {
              router.push(
                createRoute(paths.storylines.details).link({
                  storylineId: storylineCategory.storyline.id,
                })
              );
            }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default Page;
