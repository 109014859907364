"use client";
import * as React from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { Autocomplete, TextField, debounce } from "@mui/material";
import { useDispatch, useSelector } from "src/store";
import { thunks as productsThunks } from "src/thunks/products";
import { Product } from "src/types/product";
import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";

export interface LeadMagnetFilters {
  text?: string;
  product?: Product | null;
}

interface LeadMagnetListFiltersProps {
  onFiltersChange: (filters: LeadMagnetFilters) => void;
}

export const LeadMagnetListFilters: React.FC<LeadMagnetListFiltersProps> = (
  props
) => {
  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const { t } = useTranslation();

  const [filters, setFilters] = React.useState<LeadMagnetFilters>({
    text: "",
    product: null,
  });

  const { isLoading: isProductsLoading, items: products } = useSelector(
    (state) => state.products
  );

  const [productInputValue, setProductInputValue] = React.useState("");

  const fetchProducts = React.useMemo(
    () =>
      debounce(async (request: string) => {
        await dispatch(
          productsThunks.getProducts(strategyId!, {
            top: 25,
            page: 1,
            text: request,
          })
        );
      }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategyId]
  );

  React.useEffect(() => {
    let active = true;

    if (active) {
      fetchProducts(productInputValue);
    }

    return () => {
      active = false;
    };
  }, [fetchProducts, productInputValue]);

  const onTextChange = debounce(async (text: string) => {
    const newFilters = {
      ...filters,
      text: text,
    };
    setFilters(newFilters);
    props.onFiltersChange(newFilters);
  }, 400);

  return (
    <div>
      <Stack
        spacing={2}
        padding={3}
        justifyContent={"space-between"}
        direction={{
          xs: "column",
          sm: "row",
        }}
      >
        <TextField
          size="medium"
          fullWidth
          label={t(tokens.leadMagnets.list.filters.text)}
          onChange={(event) => {
            onTextChange(event.target.value);
          }}
          name="text"
        />

        <Autocomplete
          getOptionLabel={(option: Product) => `${option.name}`}
          options={products.items}
          fullWidth
          value={filters.product}
          loading={isProductsLoading}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(event: any, newValue: Product | null) => {
            const newFilters = {
              ...filters,
              product: newValue ?? null,
            };
            setFilters(newFilters);
            props.onFiltersChange(newFilters);
            setProductInputValue("");
          }}
          onInputChange={(event, newInputValue) => {
            setProductInputValue(newInputValue);
          }}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              size="small"
              fullWidth
              label={t(tokens.leadMagnets.list.filters.product)}
              name="product"
            />
          )}
        />
      </Stack>

      <Divider />
    </div>
  );
};
