import { useMemo, type FC, useState, useRef, useEffect } from "react";
//import Camera01Icon from '@untitled-ui/icons-react/build/esm/Camera01';
//import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {
  // Divider,
  Stack,
  //SvgIcon,
  // Switch,
  TextField,
  Button,
  debounce,
  Drawer,
  Typography,
  IconButton,
  SvgIcon,
  Autocomplete,
  FormHelperText,
  CircularProgress,
  Box,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "src/hooks/use-auth";
import { AuthContextType } from "src/contexts/auth/jwt-context";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { useDispatch } from "src/store";
import { thunks as thunksHost } from "src/thunks/host";

import toast from "react-hot-toast";
import LoadingButton from "@mui/lab/LoadingButton";
import { Account } from "src/types/account";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";

interface AccountImpersonationDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AccountImpersonationDrawer: FC<AccountImpersonationDrawerProps> = (
  props
) => {
  const { isOpen, onClose } = props;
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const { impersonate } = useAuth<AuthContextType>();
  const dispatch = useDispatch();
  const autocompleteRef = useRef<HTMLElement>();
  const [options, setOptions] = useState<Account[]>([]);

  const [userInputValue, setUserInputValue] = useState("");
  const [fetchingUsers, setFetchingUsers] = useState<boolean>(false);

  //const { organization } = useSelector((state) => state.accountOrganization);

  const router = useRouter();

  const fetchUsers = useMemo(
    () =>
      debounce(async (request: string) => {
        setFetchingUsers(true);
        const data = await dispatch(
          thunksHost.getUsers({ top: 20, page: 1, text: request })
        );
        setFetchingUsers(false);
        if (data) {
          setOptions([...data.items]);
        }
      }, 400),
    [dispatch]
  );

  useEffect(() => {
    let active = true;

    if (active) {
      fetchUsers(userInputValue);
    }

    return () => {
      active = false;
    };
  }, [dispatch, fetchUsers, userInputValue]);

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 500,
          p: 3,
        },
      }}
    >
      <Formik
        initialValues={{
          userId: "",
          submit: null,
        }}
        onSubmit={async (values, helpers): Promise<void> => {
          try {
            await impersonate(values.userId);
            onClose();
            //router.push(paths.calendar);
            router.push(paths.topics.list);
          } catch (err) {
            console.error(err);
            toast.error(err.Message);
            helpers.setStatus({ success: false });
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={Yup.object({
          userId: Yup.string().required(t(tokens.general.validators.required)!),
        })}
      >
        {(props) => (
          <Form placeholder={""}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{
                pb: 3,
              }}
            >
              <Typography variant="h6">
                {t(tokens.account.impersonate.form.header)}
              </Typography>
              <Stack
                alignItems="center"
                direction="row"
                spacing={0.5}
              >
                <IconButton
                  color="inherit"
                  onClick={() => onClose()}
                >
                  <SvgIcon>
                    <XIcon />
                  </SvgIcon>
                </IconButton>
              </Stack>
            </Stack>

            <Stack spacing={3}>
              <Autocomplete
                id="country-select-demo"
                ref={autocompleteRef}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : `${option.email}`
                }
                renderOption={(props, option) => (
                  <li
                    {...props}
                    key={option.id}
                  >
                    <Grid
                      container
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          width: "calc(100% - 44px)",
                          wordWrap: "break-word",
                        }}
                      >
                        <Box component="span">{option.email}</Box>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                        >
                          {option.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
                autoHighlight
                filterOptions={(x) => x.filter((option) => option.email)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                noOptionsText={"No users"}
                onChange={(event: any, newValue: Account | null) => {
                  props.setFieldValue("userId", newValue?.id ?? "");
                }}
                onInputChange={(event, newInputValue) => {
                  setUserInputValue(newInputValue);
                }}
                loading={fetchingUsers}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label={t(tokens.account.impersonate.form.searchForUser)}
                      fullWidth
                      error={!!(props.touched.userId && props.errors.userId)}
                      helperText={props.touched.userId && props.errors.userId}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {fetchingUsers ? (
                              <CircularProgress
                                color="inherit"
                                size={20}
                                sx={{ marginTop: "-20px" }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  </>
                )}
              />

              {props.errors.submit && (
                <FormHelperText
                  error
                  sx={{ mt: 3 }}
                >
                  {props.errors.submit as string}
                </FormHelperText>
              )}

              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ pt: 3, pb: 3 }}
              >
                <Button
                  color="inherit"
                  onClick={() => onClose()}
                  size="small"
                >
                  {t(tokens.general.buttons.cancel)}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                >
                  {t(tokens.account.impersonate.form.buttons.impersonate)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};
