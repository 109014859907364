"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { NoteBlank as NoteBlankIcon } from "@phosphor-icons/react/dist/ssr/NoteBlank";
import { Sparkle as SparkleIcon } from "@phosphor-icons/react/dist/ssr/Sparkle";
import { BoxArrowDown as BoxArrowDownIcon } from "@phosphor-icons/react/dist/ssr/BoxArrowDown";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

export interface TopicOptionsProps {
  onNewTopic?: () => void;
  onSelectFromExisting: () => void;
  onGenerateTopic: () => void;
}

export function TopicOptions({
  onNewTopic,
  onSelectFromExisting,
  onGenerateTopic,
}: TopicOptionsProps): React.JSX.Element {
  const { t } = useTranslation();

  const categoryOptions = React.useMemo(() => {
    return [
      {
        title: t(tokens.contentPlaceholders.options.newTopic),
        value: "newTopic",
        icon: <NoteBlankIcon size="36" />,
      },
      {
        title: t(tokens.contentPlaceholders.options.selectTopic),
        value: "existingTopic",
        icon: <BoxArrowDownIcon size="36" />,
      },
      {
        title: t(tokens.contentPlaceholders.options.generateTopic),
        value: "generateTopic",
        icon: <SparkleIcon size="36" />,
      },
    ] satisfies {
      title: string;
      value: string;
      disabled?: boolean;
      icon?: React.ReactNode;
    }[];
  }, [t]);

  return (
    <Stack spacing={2}>
      {categoryOptions.map((option) => (
        <Card
          key={option.value}
          onClick={() => {
            if (option.value === "newTopic") {
              onNewTopic?.();
            } else if (option.value === "existingTopic") {
              onSelectFromExisting();
            } else if (option.value === "generateTopic") {
              onGenerateTopic();
            }
          }}
        >
          <CardActionArea>
            <CardContent>
              <Stack
                direction={"row"}
                spacing={2}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    color: "var(--mui-palette-primary-main)",
                  }}
                >
                  {option.icon}
                </Box>
                <Typography
                  sx={{
                    color: "var(--mui-palette-text-primary)",
                  }}
                  variant="h6"
                >
                  {option.title}
                </Typography>
                {/* <Typography
                  sx={{
                    color: "var(--mui-palette-text-secondary)",
                  }}
                  variant="body2"
                >
                  {option.description}
                </Typography> */}
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Stack>
  );
}
