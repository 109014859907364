"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CaretDown as CaretDownIcon } from "@phosphor-icons/react/dist/ssr/CaretDown";
import { List as ListIcon } from "@phosphor-icons/react/dist/ssr/List";

import type { NavItemConfig } from "../../types/nav";
import { paths } from "../../paths";
import { isNavItemActive } from "../../lib/is-nav-item-active";
import { usePathname } from "../../hooks/use-pathname";
import { Dropdown } from "../../components/core/dropdown/dropdown";
import { DropdownPopover } from "../../components/core/dropdown/dropdown-popover";
import { DropdownTrigger } from "../../components/core/dropdown/dropdown-trigger";
import { RouterLink } from "../../components/core/link";

import { MobileNav } from "./mobile-nav";
import { useTranslation } from "react-i18next";
import { usePopover } from "../../hooks/use-popover";
import { Language, languages } from "../../language";
import { Avatar, Tooltip } from "@mui/material";
import { UserPopover } from "./user-popover/user-popover";
import { LanguagePopover } from "./language-popover";
import { useAuth } from "src/hooks/use-auth";
import { tokens } from "src/locales/tokens";
import { DynamicLogo } from "src/components/core/logo";
import { useWindowScroll } from "src/hooks/use-window-scroll";
import { AccountImpersonationDrawer } from "./account-impersonation-drawer";
import { useSelector } from "src/store";

interface Item {
  disabled?: boolean;
  external?: boolean;
  popover?: React.ReactNode;
  path?: string;
  title: string;
}

export function MainNav(): React.JSX.Element {
  const [openNav, setOpenNav] = React.useState<boolean>(false);
  const pathname = usePathname();

  const [elevate, setElevate] = React.useState<boolean>(false);
  const offset = 30;
  const delay = 0;
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const [impersonateDrawerOpen, setImpersonateDrawerOpen] =
    React.useState<boolean>(false);
  const { items: strategies } = useSelector((state) => state.strategies);
  const auth = useAuth();
  const abilityToImpersonate = auth.account?.isHost;

  const items = React.useMemo(() => {
    if (isAuthenticated) {
      return [
        ...(strategies.length > 0
          ? [
              {
                title: t(tokens.landing.nav.panel),
                //path: paths.calendar,
                path: paths.topics.list,
              },
            ]
          : []),
      ] as Item[];
    } else {
      return [
        {
          title: t(tokens.landing.nav.singIn),
          path: paths.account.signIn,
        },
      ] as Item[];
    }
  }, [isAuthenticated, strategies.length, t]);

  const handleWindowScroll = React.useCallback((): void => {
    if (window.scrollY > offset) {
      setElevate(true);
    } else {
      setElevate(false);
    }
  }, []);

  useWindowScroll({
    handler: handleWindowScroll,
    delay,
  });

  return (
    <React.Fragment>
      <Box
        component="header"
        sx={{
          bgcolor: "transparent",
          backdropFilter: "blur(6px)",
          // color: 'var(--mui-palette-common-white)',
          left: 0,
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: "var(--MainNav-zIndex)",
          transition: (theme) =>
            theme.transitions.create("box-shadow, background-color", {
              easing: theme.transitions.easing.easeInOut,
              duration: 100,
            }),
          ...(elevate && {
            backgroundColor: "var(--mui-palette-background-default)",
            boxShadow: 8,
          }),
        }}
      >
        <Box
          sx={{
            borderBottom: "1px solid var(--MainNav-divider)",
            display: "flex",
            flex: "1 1 auto",
            minHeight: "var(--MainNav-height)",
            px: { xs: 0, lg: 3 },
            py: 0,
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              minHeight: "var(--MainNav-height)",
              py: "16px",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: "center", flex: "1 1 auto" }}
            >
              <DynamicLogo
                height={32}
                width={122}
              />
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
                flex: "1 1 auto",
                justifyContent: "flex-end",
              }}
            >
              {/* <Box component="nav" sx={{ display: { xs: 'none', md: 'block' } }}>
                <Stack component="ul" direction="row" spacing={1} sx={{ listStyle: 'none', m: 0, p: 0 }}>
                  <NavItem pathname={pathname} title="Pages">
                    <PagesPopover />
                  </NavItem>
                </Stack>
              </Box> */}
              <Box
                component="nav"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <Stack
                  component="ul"
                  direction="row"
                  spacing={1}
                  sx={{ listStyle: "none", m: 0, p: 0 }}
                >
                  {items.map((item) => {
                    return (
                      <NavItem
                        href={item.path}
                        external={item.external}
                        key={item.title}
                        // pathname={item.path}
                        pathname={pathname}
                        title={item.title}
                      />
                    );
                  })}
                </Stack>
              </Box>

              <LanguageSwitch />
              {isAuthenticated && (
                <UserButton
                  onImpersonate={() => {
                    setImpersonateDrawerOpen(true);
                  }}
                />
              )}
              <IconButton
                onClick={() => {
                  setOpenNav(true);
                }}
                sx={{ display: { xs: "flex", md: "none" } }}
              >
                <ListIcon />
              </IconButton>
            </Stack>
          </Container>
        </Box>
      </Box>
      <MobileNav
        onClose={() => {
          setOpenNav(false);
        }}
        open={openNav}
      />
      {abilityToImpersonate && (
        <AccountImpersonationDrawer
          isOpen={impersonateDrawerOpen}
          onClose={() => setImpersonateDrawerOpen(false)}
        />
      )}
    </React.Fragment>
  );
}

export interface UserButtonProps {
  onImpersonate?: () => void;
}

function UserButton({ onImpersonate }: UserButtonProps): React.JSX.Element {
  const popover = usePopover<HTMLButtonElement>();
  const auth = useAuth();

  return (
    <React.Fragment>
      <Box
        component="button"
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          border: "none",
          background: "transparent",
          cursor: "pointer",
          p: 0,
        }}
      >
        <Avatar src={auth.account?.profileImageUrl} />
      </Box>
      <UserPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        onImpersonate={onImpersonate}
        open={popover.open}
      />
    </React.Fragment>
  );
}

function LanguageSwitch(): React.JSX.Element {
  const { i18n, t } = useTranslation();
  const popover = usePopover<HTMLButtonElement>();
  const language = (i18n.language || "en") as Language;
  const flag = languages[language];

  return (
    <React.Fragment>
      <Tooltip title={t(tokens.general.language)}>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
          sx={{ display: { lg: "inline-flex" } }}
        >
          <Box sx={{ height: "24px", width: "24px" }}>
            <Box
              alt={language}
              component="img"
              src={flag}
              sx={{ height: "auto", width: "100%" }}
            />
          </Box>
        </IconButton>
      </Tooltip>
      <LanguagePopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </React.Fragment>
  );
}

interface NavItemProps extends Omit<NavItemConfig, "key" | "items"> {
  children?: React.ReactNode;
  pathname: string;
}

export function NavItem({
  children,
  disabled,
  external,
  href,
  matcher,
  pathname,
  title,
}: NavItemProps): React.JSX.Element {
  const active = isNavItemActive({
    disabled,
    external,
    href,
    matcher,
    pathname,
  });
  const hasPopover = Boolean(children);

  const element = (
    <Box
      component="li"
      sx={{
        userSelect: "none",
      }}
    >
      <Box
        {...(hasPopover
          ? {
              onClick: (event: React.MouseEvent<HTMLElement>): void => {
                event.preventDefault();
              },
              role: "button",
            }
          : {
              ...(href
                ? {
                    component: external ? "a" : RouterLink,
                    href,
                    target: external ? "_blank" : undefined,
                    rel: external ? "noreferrer" : undefined,
                  }
                : { role: "button" }),
            })}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          color: "var(--MainNav-color)",
          cursor: "pointer",
          display: "flex",
          flex: "0 0 auto",
          gap: 1,
          p: "6px 16px",
          textAlign: "left",
          textDecoration: "none",
          whiteSpace: "nowrap",
          ...(disabled && {
            bgcolor: "var(--mui-palette-action-disabledBackground)",
            color: "var(--mui-action-disabled)",
            cursor: "not-allowed",
          }),
          ...(active && { color: "var(--NavItem-active-color)" }),
          "&:hover": {
            ...(!disabled &&
              !active && {
                bgcolor: "rgba(255, 255, 255, 0.04)",
                color: "var(--mui-palette-neutral-500)",
              }),
          },
        }}
        tabIndex={0}
      >
        <Box
          component="span"
          sx={{
            flex: "1 1 auto",
          }}
        >
          <Typography
            component="span"
            sx={{
              color: "inherit",
              fontSize: "0.875rem",
              fontWeight: 500,
              lineHeight: "28px",
            }}
          >
            {title}
          </Typography>
        </Box>
        {hasPopover ? (
          <Box
            sx={{
              alignItems: "center",
              color: "inherit",
              display: "flex",
              flex: "0 0 auto",
            }}
          >
            <CaretDownIcon fontSize="var(--icon-fontSize-sm)" />
          </Box>
        ) : null}
      </Box>
    </Box>
  );

  if (hasPopover) {
    return (
      <Dropdown>
        <DropdownTrigger>{element}</DropdownTrigger>
        <DropdownPopover
          PaperProps={{ sx: { width: "800px", maxWidth: "100%" } }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
        >
          {children}
        </DropdownPopover>
      </Dropdown>
    );
  }

  return element;
}
