import React, { FC, useCallback, useEffect } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Drawer,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";

import { tokens } from "src/locales/tokens";
import { useTranslation } from "react-i18next";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { Topic } from "src/types/topic";
import { TopicFilters, TopicListFilters } from "../topics/topic-list-filters";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/topics";
import LoadingButton from "@mui/lab/LoadingButton";
import { ErrorMessage } from "src/sections/common/error-message";
import { LoadIndicator } from "src/sections/common/load-indicator";
import { EmptyMessage } from "src/sections/common/empty-message";

interface SelectTopicDrawerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (topic: Topic) => void;
}

export const SelectTopicDrawer: FC<SelectTopicDrawerProps> = (props) => {
  const { open, onClose, onSelect } = props;
  const { t } = useTranslation();
  
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      // hideBackdrop
      open={open}
      ModalProps={{
        keepMounted: true,
      }}
      onClose={onClose}
      slotProps={{
        backdrop: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          width: "100%",
          maxWidth: 700,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
        }}
      >
        <Typography variant="h6">
          {t(tokens.calendar.selectTopic.pageHeader)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <IconButton
            size="small"
            color="inherit"
            onClick={() => {
              onClose();
            }}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      {open && <DrawerConent onSelect={onSelect} />}
    </Drawer>
  );
};

interface DrawerConentProps {
  onSelect: (topic: Topic) => void;
}

const DrawerConent: FC<DrawerConentProps> = (props) => {
  const { onSelect } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { strategyId } = useSelector((state) => state.settings);

  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.topics
  );

  const [filters, setFilters] = React.useState<TopicFilters>({
    text: "",
    product: null,
    storyline: null,
  });

  const loadTopics = useCallback(
    async (lastItemDateTime: number | undefined) => {
      await dispatch(
        thunks.getTopics(strategyId!, {
          top: 10,
          lastItemDateTime: lastItemDateTime ?? "",
          text: filters.text ?? "",
          productId: filters.product?.id ?? "",
          storyLineId: filters.storyline?.id ?? "",
        })
      );
    },
    [
      dispatch,
      filters.product?.id,
      filters.storyline?.id,
      filters.text,
      strategyId,
    ]
  );

  useEffect(() => {
    loadTopics(undefined);
  }, [loadTopics]);

  useEffect(() => {
    return () => {
      dispatch(thunks.clearTopics());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        display: "inline-block",
        height: "100%",
        overflow: "auto",
        paddingBottom: 1,
      }}
    >
      <Box
        sx={{
          p: 3,
        }}
      >
        <Card>
          <TopicListFilters
            showVerticalFilters={true}
            onFiltersChange={(filters) => {
              setFilters(filters);
            }}
          />
        </Card>

        <Stack
          spacing={2}
          sx={{ mt: 2 }}
        >
          {!isLoading && items.items.length === 0 && (
            <Card>
              <CardContent>
                <EmptyMessage
                  text={t(tokens.calendar.selectTopic.noTopicsFound)}
                />
              </CardContent>
            </Card>
          )}

          {isLoading && items.items.length === 0 && (
            <>
              <Card>
                <CardContent>
                  <LoadIndicator />
                </CardContent>
              </Card>
            </>
          )}

          {hasLoadingError && !isLoading && (
            <Card>
              <CardContent>
                <ErrorMessage
                  text={t(tokens.calendar.selectTopic.errorLoadingTopics)}
                />
              </CardContent>
            </Card>
          )}
          {!hasLoadingError && (
            <>
              {items.items.map((topic) => (
                <TopicItem
                  key={topic.id}
                  topic={topic}
                  onSelect={(topic) => {
                    onSelect(topic);
                  }}
                />
              ))}
              {items.items.length > 0 &&
                items.totalCount > items.items.length && (
                  <LoadingButton
                    loading={isLoading}
                    variant="outlined"
                    fullWidth
                    disabled={isLoading}
                    onClick={() => {
                      loadTopics(
                        items.items.length > 0
                          ? items.items[items.items.length - 1].createdDate
                          : undefined
                      );
                    }}
                  >
                    {t(tokens.general.buttons.loadMore)}
                  </LoadingButton>
                )}
            </>
          )}
        </Stack>
      </Box>
    </div>
  );
};

interface TopicItemProps {
  topic: Topic;
  onSelect?: (topic: Topic) => void;
}

export const TopicItem: FC<TopicItemProps> = (props) => {
  const { topic, onSelect } = props;
  return (
    <Card>
      <CardActionArea
        onClick={() => {
          onSelect?.(topic);
        }}
      >
        <CardContent>
          <Stack spacing={1}>
            <Typography variant="h6">{topic.name}</Typography>
            <Typography>{topic.description}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
