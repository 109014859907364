import { useEffect, type FC, type ReactNode } from "react";
import PropTypes from "prop-types";
import { api } from "src/config";
import { useDispatch, useSelector } from "src/store";
import signalR from "use-signalr-hub";
import { slice as strategySlice } from "src/slices/strategy";
import { StrategyPart } from "src/types/strategy";
import { HttpTransportType } from "@microsoft/signalr";
import { thunks as strategyThunks } from "src/thunks/strategy";
import { thunks as strategiesThunks } from "src/thunks/strategies";
import { thunks as targetAudiencesThunks } from "src/thunks/target-audiences";
import { thunks as productsThunks } from "src/thunks/products";

interface SignalRContextProps {
  children?: ReactNode;
}

export const SignalRAppContext: FC<SignalRContextProps> = (props) => {
  const { children } = props;
  const { strategyId } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const hub = signalR.useHub(`${api}/strategyHub`, {
    onConnected: (hub) => {
      // Connected to hub

      hub.on("SetContentTemplates", async (strategyId, data) => {
        console.log("SetContentTemplates", strategyId, data);
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetLeadMagnets", async (strategyId, data) => {
        console.log("SetLeadMagnets", strategyId, data);
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetTargetAudiences", async (strategyId, data) => {
        console.log("SetTargetAudiences", strategyId, data);
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        await dispatch(
          targetAudiencesThunks.getTargetAudiences(strategyId!, {
            page: 1,
            top: 10,
          })
        );
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetProducts", async (strategyId, data) => {
        console.log("SetProducts", strategyId, data);
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        await dispatch(
          productsThunks.getProducts(strategyId!, {
            page: 1,
            top: 10,
          })
        );
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetFears", async (strategyId, data) => {
        console.log("SetFears", strategyId, data);
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        await dispatch(strategyThunks.getStrategy(strategyId));
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetStorylines", async (strategyId, data) => {
        console.log("SetStorylines", strategyId, data);
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetInstagramProfile", async (strategyId, data) => {
        console.log("SetInstagramProfile", strategyId, data);
        await dispatch(strategyThunks.getStrategy(strategyId));
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetStrategyName", async (strategyId, data) => {
        console.log("SetStrategyName", strategyId, data);
        await dispatch(strategyThunks.getStrategy(strategyId));
        await dispatch(strategyThunks.getStrategyParts(strategyId));

        await dispatch(strategiesThunks.getStrategies());
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
      hub.on("SetAboutMe", async (strategyId, data) => {
        console.log("SetAboutMe", strategyId, data);
        await dispatch(strategyThunks.getStrategy(strategyId));
        await dispatch(strategyThunks.getStrategyParts(strategyId));
        //await dispatch(strategiesThunks.getStrategies());
        strategySlice.actions.getStrategyPart(data as StrategyPart);
      });
    },
    onReconnected: (connectionId) => {
      if (strategyId && hub) {
        hub.invoke("RegisterClient", strategyId);
      }
    },
    onDisconnected: (error) => {
      console.log("Disconnected from hub");
    },
    onError: (error) => {
      console.log("Error from hub");
    },

    automaticReconnect: true,
    httpTransportTypeOrOptions: HttpTransportType.WebSockets,
  });

  useEffect(() => {
    if (strategyId && hub) {
      hub.invoke("RegisterClient", strategyId);
    }
    console.log(strategyId);
  }, [hub, strategyId]);

  return <>{children}</>;
};

SignalRAppContext.propTypes = {
  children: PropTypes.node.isRequired,
};
