"use client";

import * as React from "react";
import type {
  DateSelectArg,
  EventClickArg,
  EventDropArg,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import type { EventResizeDoneArg } from "@fullcalendar/interaction";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Calendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

import { CalendarContext } from "./calendar-context";
import { EventContent } from "./event-content";
import { Toolbar } from "./toolbar";
import type { ViewMode } from "./types";
import { useDialog } from "src/hooks/use-dialog";
import { paths } from "src/paths";
import { ContentPlaceholderDrawer } from "src/sections/dashboard/calendar/content-placeholder-edit-drawer";
import { useTranslation } from "react-i18next";
import { ContentGoalType } from "src/types/content-goal-type";
import { tokens } from "src/locales/tokens";
import { ScheduleDrawer } from "./schedule-drawer";

interface CreateDialogData {
  range?: { date: Date };
}

const plugins = [
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  timeGridPlugin,
  timelinePlugin,
];

export interface CalendarViewProps {
  view?: ViewMode;
  date: Date;
  onTopicUpdate?: () => void;
  onDateChange?: (date: Date) => void;
}

export function CalendarView({
  view = "dayGridMonth",
  date,
  onDateChange,
  onTopicUpdate,
}: CalendarViewProps): React.JSX.Element {
  const {
    events,
    currentEventId,
    setCurrentEventId,
    // createEvent,
    // deleteEvent,
    updateEvent,
  } = React.useContext(CalendarContext);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const calendarRef = React.useRef<Calendar | null>(null);

  const createDialog = useDialog<CreateDialogData>();
  const [isContentDrawerOpen, setIsContentDrawerOpen] = React.useState(false);
  const [isScheduleDrawerOpen, setIsScheduleDrawerOpen] = React.useState(false);

  const currentEvent = currentEventId ? events.get(currentEventId) : undefined;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);

  const handleViewChange = React.useCallback(
    (value: ViewMode) => {
      const calendarApi = calendarRef.current?.getApi();

      if (calendarApi) {
        calendarApi.changeView(value);
      }

      navigate(`${paths.calendar}?view=${value}`);
    },
    [navigate]
  );

  const handleDateToday = React.useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();

    if (calendarApi) {
      calendarApi.today();
      onDateChange?.(calendarApi.getDate());
      //setDate(calendarApi.getDate());
    }
  }, [onDateChange]);

  const handleDatePrev = React.useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();

    if (calendarApi) {
      calendarApi.prev();
      onDateChange?.(calendarApi.getDate());
      //setDate(calendarApi.getDate());
    }
  }, [onDateChange]);

  const handleDateNext = React.useCallback(() => {
    const calendarApi = calendarRef.current?.getApi();

    if (calendarApi) {
      calendarApi.next();
      onDateChange?.(calendarApi.getDate());
      //setDate(calendarApi.getDate());
    }
  }, [onDateChange]);

  const handleAdd = React.useCallback(() => {
    createDialog.handleOpen();
  }, [createDialog]);

  const handleSelect = React.useCallback(
    (arg: DateSelectArg) => {
      const calendarApi = calendarRef.current?.getApi();

      if (calendarApi) {
        calendarApi.unselect();
      }
      setSelectedDate(arg.start);
      setCurrentEventId(undefined);
      setIsContentDrawerOpen(true);
      //createDialog.handleOpen({ range: { date: arg.start } });
      //setIsDrawerOpen(true);
    },
    [setCurrentEventId]
  );

  const handleEventSelect = React.useCallback(
    (arg: EventClickArg) => {
      setCurrentEventId(arg.event.id);
      setIsContentDrawerOpen(true);
    },
    [setCurrentEventId]
  );

  const handleEventResize = React.useCallback(
    (arg: EventResizeDoneArg) => {
      const { event } = arg;
      updateEvent(event.id, {
        allDay: event.allDay,
        date: event.start!,
      });
    },
    [updateEvent]
  );

  const handleEventDrop = React.useCallback(
    (arg: EventDropArg) => {
      const { event } = arg;
      updateEvent(event.id, {
        allDay: event.allDay,
        date: event.start!,
      });
    },
    [updateEvent]
  );

  const getContentGoalType = React.useCallback(
    (contentGoalType: ContentGoalType) => {
      switch (contentGoalType) {
        case ContentGoalType.Engagement:
          return t(tokens.general.contentGoalTypes.engagement);
        case ContentGoalType.Community:
          return t(tokens.general.contentGoalTypes.community);
        case ContentGoalType.Selling:
          return t(tokens.general.contentGoalTypes.selling);
        default:
          return "";
      }
    },
    [t]
  );

  const getEvents = React.useCallback(() => {
    return Array.from(events.values()).map((contentPlaceholder) => ({
      id: contentPlaceholder.id,
      title: contentPlaceholder.topic?.name ?? "",
      date: new Date(contentPlaceholder.date!),
      contentGoalType: contentPlaceholder.contentGoalType,
      contentGoalTypeTitle: getContentGoalType(
        contentPlaceholder.contentGoalType
      ),
      channelName: contentPlaceholder.channel?.name ?? "",
      allDay: true,
    }));
  }, [events, getContentGoalType]);

  return (
    <React.Fragment>
      <Stack spacing={4}>
        <Toolbar
          date={date}
          onAdd={handleAdd}
          onViewChange={handleViewChange}
          onNext={handleDateNext}
          onPrev={handleDatePrev}
          onToday={handleDateToday}
          onPlanSchedule={() => setIsScheduleDrawerOpen(true)}
          view={view}
        />
        <Card sx={{ overflowX: "auto" }}>
          <Box sx={{ minWidth: "1000px" }}>
            <Calendar
              allDayMaintainDuration
              dayMaxEventRows={5}
              firstDay={1}
              droppable
              editable
              locale={i18n.language}
              eventClick={handleEventSelect}
              eventContent={EventContent}
              eventDisplay="block"
              eventDrop={handleEventDrop}
              height={"auto"}
              eventResizableFromStart
              eventResize={handleEventResize}
              events={getEvents()}
              dayMaxEvents={false}
              headerToolbar={false}
              initialDate={date}
              initialView={view}
              plugins={plugins}
              ref={calendarRef}
              rerenderDelay={10}
              select={handleSelect}
              selectable
              weekends
            />
          </Box>
        </Card>
      </Stack>
      <ContentPlaceholderDrawer
        contentPlaceholder={currentEvent}
        isOpen={isContentDrawerOpen}
        date={selectedDate}
        onClose={() => {
          setIsContentDrawerOpen(false);
          setCurrentEventId(undefined);
          onTopicUpdate?.();
        }}
      />
      <ScheduleDrawer
        open={isScheduleDrawerOpen}
        onClose={() => {
          setIsScheduleDrawerOpen(false);
        }}
      />
    </React.Fragment>
  );
}
