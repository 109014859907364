import * as React from "react";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import { Metadata } from "src/types/metadata";
import { config } from "src/config";
import { ViewMode } from "src/sections/dashboard/calendar/types";
import { CalendarProvider } from "src/sections/dashboard/calendar/calendar-context";
import { CalendarView } from "src/sections/dashboard/calendar/calendar-view";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/content-placeholders";
import moment from "moment";

const metadata = {
  title: `Calendar | Dashboard | ${config.site.name}`,
} satisfies Metadata;

export function Page(): React.JSX.Element {
  const { view = "dayGridMonth" } = useExtractSearchParams();

  const dispatch = useDispatch();
  const { strategyId } = useSelector((state) => state.settings);
  const [date, setDate] = React.useState<Date>(new Date());
  const { items, hasLoadingError, isLoading } = useSelector(
    (state) => state.contentPlaceholders
  );

  const loadItems = React.useCallback(
    async (startDate: Date, endDate: Date) => {
      await dispatch(
        thunks.getContentPlaceholders(strategyId!, {
          startDate: moment(startDate).valueOf().toString(),
          endDate: moment(endDate).valueOf().toString(),
        })
      );
    },
    [dispatch, strategyId]
  );

  React.useEffect(() => {
    loadItems(
      moment(date).startOf("month").subtract(7, "days").toDate(),
      moment(date).endOf("month").add(7, "days").toDate()
    );
  }, [date, loadItems, strategyId]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{metadata.title}</title>
      </Helmet>
      <Box
        sx={{
          maxWidth: "var(--Content-maxWidth)",
          m: "var(--Content-margin)",
          p: "var(--Content-padding)",
          width: "var(--Content-width)",
        }}
      >
        <CalendarProvider events={items}>
          <CalendarView
            view={view}
            date={date}
            onDateChange={setDate}
            onTopicUpdate={() => {
              loadItems(
                moment(date).startOf("month").subtract(15, "days").toDate(),
                moment(date).endOf("month").add(15, "days").toDate()
              );
            }}
          />
        </CalendarProvider>
      </Box>
    </React.Fragment>
  );
}

function useExtractSearchParams(): { view?: ViewMode } {
  const [searchParams] = useSearchParams();

  return {
    view: (searchParams.get("view") || undefined) as ViewMode | undefined,
  };
}

export default Page;
