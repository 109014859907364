import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Topic, TopicResponse } from "src/types/topic";

interface TopicsState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: TopicResponse;
}

type GetTopicsAction = PayloadAction<TopicResponse>;
type UpdateTopicAction = PayloadAction<Topic>;
type TopicsLoadingStatusAction = PayloadAction<boolean>;
type TopicsErrorStatusAction = PayloadAction<boolean>;

const initialState: TopicsState = {
  isLoading: false,
  hasLoadingError: false,
  items: {
    items: [],
    totalCount: 0,
    top: 10,
    page: 1,
    startDate: null,
    endDate: null,
    lastItemDateTime: null,
  },
};

const reducers = {
  setLoadingStatus: (
    state: TopicsState,
    action: TopicsLoadingStatusAction
  ): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (
    state: TopicsState,
    action: TopicsErrorStatusAction
  ): void => {
    state.hasLoadingError = action.payload;
  },
  getTopics: (state: TopicsState, action: GetTopicsAction): void => {
    const currentItems = state.items.items;
    state.items = action.payload;
    if (action.payload.lastItemDateTime) {
      state.items.items = [...currentItems, ...action.payload.items];
    }
  },
  clearTopics: (state: TopicsState): void => {
    state.items = initialState.items;
  },
  updateTopic: (state: TopicsState, action: UpdateTopicAction): void => {
    const topic = action.payload;
    const index = state.items.items.findIndex((t) => t.id === topic.id);
    if (index !== -1) {
      state.items.items[index] = topic;
    }
  },
};

export const slice = createSlice({
  name: "topics",
  initialState,
  reducers,
});

export const { reducer } = slice;
